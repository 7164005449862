import React, { Fragment, useState, useContext, useEffect, useCallback } from 'react';
import { Alert, Card, CardBody, Button } from 'reactstrap';
import Loader from '../../components/common/Loader';
import FalconCardHeader from '../../components/common/FalconCardHeader';
import { isIterableArray } from '../../utils';
import { useHistory } from 'react-router-dom';
import GuardsTable from '../../components/guards/GuardsTable';
import { lang } from '../../lang';
import AppContext from '../../context/Context';
import { request, APIs } from '../../request';
import { safeReq } from '../../utils/request';
import { toast } from 'react-toastify';
import Confirm from '../../components/alert/confirm';

const Guards = () => {
  const history = useHistory();
  const [guards, setGuards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedGuardId, setSelectedGuardId] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const { selectedStore } = useContext(AppContext);

  const fetchData = useCallback(async () => {
    const { data } = await request.GET(APIs.STORE_STAFF, { store_id: selectedStore.id });
    setGuards(data.data);
    setLoading(false);
  }, [selectedStore.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onDeleteClicked = useCallback(id => {
    setShowDeleteAlert(true);
    setSelectedGuardId(id);
  }, []);

  const deleteGuard = useCallback(() => {
    safeReq(async () => {
      setShowDeleteAlert(false);
      setLoading(true);
      await request.DELETE(APIs.STAFF, { id: selectedGuardId });
      setGuards(guards.filter(el => el.id !== selectedGuardId));
      toast.success(lang.guards.deleteGuardSuccess);
      setLoading(false);
    });
  }, [guards, selectedGuardId]);
 

  return (
    <Fragment>
      <Card>
        <FalconCardHeader title={lang.guards.guardsManagment}>
          <Button color="primary" className="mr-2" onClick={() => history.push('/guards/add')}>
            {lang.guards.addGuard.title}
          </Button>
        </FalconCardHeader>
        <CardBody className="fs--1">
          {loading ? (
            <Loader />
          ) : isIterableArray(guards) ? (
            <GuardsTable guards={guards} onDeleteClicked={onDeleteClicked} />
          ) : (
            <Alert color="info" className="mb-0">
              {lang.guards.noGuardsFound}
            </Alert>
          )}
        </CardBody>
      </Card>
      <Confirm
        open={showDeleteAlert}
        confirm={deleteGuard}
        cancel={() => setShowDeleteAlert(false)}
        title={lang.guards.deleteGuardConfirmationTitle}
        body={lang.guards.deleteGuardConfirmation}
      />
    </Fragment>
  );
};

export default Guards;
