import React, { Fragment, createRef } from 'react';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Col, Row } from 'reactstrap';
import ButtonIcon from '../common/ButtonIcon';
import { Link } from 'react-router-dom';
import { lang, isAR } from '../../lang';
import TableActionsMenu from './TableActionButton';
import { ROLES } from '../../constants';

const CustomTotal = ({ sizePerPage, totalSize, page, lastIndex }) => (
  <span>
    {(page - 1) * sizePerPage + 1} to {lastIndex > totalSize ? totalSize : lastIndex} of {totalSize} —{' '}
  </span>
);

const nameFormatter = (name, data) => (
  <Link to={`/guards/edit/${data.id}`} className="font-weight-semi-bold">
    {name}
  </Link>
);

let table = createRef();
const handleNextPage = ({ page, onPageChange }) => () => {
  onPageChange(page + 1);
};

const handlePrevPage = ({ page, onPageChange }) => () => {
  onPageChange(page - 1);
};

const handleViewAll = ({ onSizePerPageChange }, newSizePerPage) => {
  onSizePerPageChange(newSizePerPage, 1);
};

const ModeratorsTable = ({ guards, onDeleteClicked }) => {
  const prepareData = () =>
    guards.map(e => {
      const res = { ...e };
      res.role = lang.roles[e.pivot.role];
      e.pivot.role === ROLES.GUARD && (res.action = <TableActionsMenu id={e.id} onDeleteClicked={onDeleteClicked} />);
      return res;
    });
  const columns = [
    {
      dataField: 'name',
      text: lang.label.name,
      formatter: nameFormatter,
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
      sort: true,
    },
    {
      dataField: 'mobile',
      text: lang.label.mobile,
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
    },
    {
      dataField: 'email',
      text: lang.label.email,
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
    },
    {
      dataField: 'role',
      classes: 'border- text-muted align-middle',
      headerClasses: 'border-0',
    },
    {
      dataField: 'action',
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
    },
  ];

  const options = {
    custom: true,
    sizePerPage: 6,
    totalSize: guards.length,
  };

  return (
    <PaginationProvider pagination={paginationFactory(options)}>
      {({ paginationProps, paginationTableProps }) => {
        const lastIndex = paginationProps.page * paginationProps.sizePerPage;

        return (
          <Fragment>
            <div className="table-responsive">
              <BootstrapTable
                ref={table}
                bootstrap4
                keyField="id"
                data={prepareData()}
                columns={columns}
                bordered={false}
                classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                rowClasses="btn-reveal-trigger border-top border-200"
                headerClasses="bg-200 text-900 border-y border-200"
                {...paginationTableProps}
              />
            </div>
            <Row noGutters className="px-1 py-3">
              <Col className="pl-3 fs--1">
                <CustomTotal {...paginationProps} lastIndex={lastIndex} />
                <ButtonIcon
                  color="link"
                  size="sm"
                  icon={`chevron-${isAR ? 'left' : 'right'}`}
                  iconAlign="right"
                  transform="down-1 shrink-4"
                  className="px-0 font-weight-semi-bold"
                  onClick={() => handleViewAll(paginationProps, guards.length)}
                >
                  {lang.btn.viewAll}
                </ButtonIcon>
              </Col>
              <Col xs="auto" className="pr-3">
                <Button
                  color={paginationProps.page === 1 ? 'light' : 'primary'}
                  size="sm"
                  onClick={handlePrevPage(paginationProps)}
                  disabled={paginationProps.page === 1}
                  className="px-4"
                >
                  {lang.btn.previous}
                </Button>
                <Button
                  color={lastIndex >= paginationProps.totalSize ? 'light' : 'primary'}
                  size="sm"
                  onClick={handleNextPage(paginationProps)}
                  disabled={lastIndex >= paginationProps.totalSize}
                  className="px-4 ml-2"
                >
                  {lang.btn.next}
                </Button>
              </Col>
            </Row>
          </Fragment>
        );
      }}
    </PaginationProvider>
  );
};

export default ModeratorsTable;
