import React, { useCallback, useEffect, useState } from 'react';
import { lang } from '../../lang';
import { SectionTitle } from '../common/sectionTitle';
import { InformationItem } from './information_item';
import StoreLocation from '../stores/StoreLocation';

export const BranchInformationSection = ({ branch, editMode, setInfo }) => {
  const [enName, setEnName] = useState(branch.name_en);
  const [name, setName] = useState(branch.name);
  const [area, setArea] = useState(branch.area);
  const [latLng, setLatLng] = useState({ lat: branch.lat, lng: branch.lng });

  useEffect(() => {
    setInfo({ enName, name, area, latLng });
  }, [enName, name, area, latLng, setInfo]);

  const onNameChange = useCallback(e => setName(e.target.value), []);
  const onNameEnChange = useCallback(e => setEnName(e.target.value), []);
  const onAreaChange = useCallback(e => setArea(e.target.value), []);

  return (
    <div>
      <SectionTitle className="mb-4">{lang.stores.informations.title}</SectionTitle>
      <InformationItem
        label={lang.brandInformation.enName}
        value={enName}
        className="mb-2"
        editMode={editMode}
        onChange={onNameEnChange}
      />
      <InformationItem
        label={lang.brandInformation.arName}
        value={name}
        className="mb-2"
        editMode={editMode}
        onChange={onNameChange}
      />
      <InformationItem
        label={lang.stores.informations.area}
        value={area}
        className="mb-2"
        editMode={editMode}
        onChange={onAreaChange}
      />
      <p className="mb-0">{lang.stores.informations.loc}</p>
      <StoreLocation setLatLng={setLatLng} locateMeControl={editMode} onChange={setLatLng} latLng={latLng} />
    </div>
  );
};
