import React, { Fragment, useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import GoogleMap from '../map/GoogleMap';
import { GPSIcon } from '../../utils/icons';

const StoreLocation = ({ latLng, setLatLng, locateMeControl = true }) => {
  const defaultLatLng = latLng || { lat: 24.71141749355165, lng: 46.674363303161165 };
  const [selectedLatlng, setSelectedLatlng] = useState(defaultLatLng);
  const [userLocation, setUserLocation] = useState(defaultLatLng);
  const [isLocationSupport, setLocationNotSupported] = useState(true);
  const [loading, setLoading] = useState(!latLng);

  useEffect(() => {
    if (latLng) {
      onLatLngChange({ lat: latLng.lat, lng: latLng.lng });
      setUserLocation({ lat: latLng.lat, lng: latLng.lng });
    } else {
      getLocation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLatLngChange = ({ lat, lng }) => {
    setLatLng({ lat, lng });
    setSelectedLatlng({ lat, lng });
  };

  const getLocation = () => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        onLatLngChange({ lat: coords.latitude, lng: coords.longitude });
        setUserLocation({ lat: coords.latitude, lng: coords.longitude });

        setLoading(false);
      });
    } else {
      setLocationNotSupported(false);
      setLoading(false);
    }
  };

  const lat = Number.parseFloat(selectedLatlng.lat).toFixed(5);
  const lng = Number.parseFloat(selectedLatlng.lng).toFixed(5);

  return (
    <Fragment>
      <h6 className="mr-2 text-grey mb-4 mt-1">
        lat: {lat} - lng: {lng}{' '}
        <span className="ml-2" style={{ position: 'relative', top: -3 }}>
          <Spinner size="sm" className="text-primary" style={{ visibility: loading ? 'visible' : 'hidden' }} />
        </span>
      </h6>
      {isLocationSupport ? (
        <div style={{ position: 'relative' }}>
          {locateMeControl ? (
            <button className="gps-btn" onClick={getLocation}>
              <span className="mx-2">
                <GPSIcon width={20} />
              </span>
            </button>
          ) : null}

          <GoogleMap
            initialCenter={userLocation}
            center={userLocation}
            mapStyle={'Default'}
            style={{ borderRadius: '10px', boxShadow: '0 0 9px #ccc' }}
            className="min-vh-50 rounded-soft"
            markerLatLng={selectedLatlng}
            onClick={(a, b, { latLng }) => onLatLngChange({ lat: latLng.lat(), lng: latLng.lng() })}
            onCursorDragend={(a, b, { latLng }) => onLatLngChange({ lat: latLng.lat(), lng: latLng.lng() })}
          />
        </div>
      ) : (
        <p>Location Not Supported</p>
      )}
    </Fragment>
  );
};

export default StoreLocation;
