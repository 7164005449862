import React, { useState, Fragment, useContext, useEffect, useCallback } from 'react';
import { Card, CardBody, CardFooter, CardHeader, Form, Nav, NavItem, NavLink, Button } from 'reactstrap';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import Alert from '../../components/alert/success';
import StoreInformation from '../../components/stores/StoreInformation';
import StoreLocation from '../../components/stores/StoreLocation';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { lang } from '../../lang';
import Context from '../../context/Context';
import { request, APIs } from '../../request';
import Loader from '../../components/common/Loader';
import { ROLES } from '../../constants';

const EditStore = () => {
  const history = useHistory();
  const { params } = useRouteMatch();
  const { handleSubmit, errors, watch, register, control } = useForm();
  const [showAlert, setShowAlert] = useState(false);
  const { brand, selectedStore, setSelectedStore, stores, setStores, user } = useContext(Context);
  const [storeInfo, setStoreInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fields, setField] = useState({});
  const [latLng, setLatLng] = useState({});
  const [step, setStep] = useState(1);

  const fetchData = useCallback(async () => {
    const targetedStoreId = params.id || selectedStore.id;
    const store = stores.find(store => `${store.id}` === `${targetedStoreId}`) || {};
    setStoreInfo(store);
    setLatLng({
      lat: store.lat,
      lng: store.lng,
    });
    setLoading(false);
  }, [params.id, stores, selectedStore.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSubmitData = async form => {
    if (step < 2) {
      setField(form);
      setStep(step + 1);
    } else if (step === 2) {
      let storeId = storeInfo.id;
      try {
        const body = { ...fields, ...form, ...latLng, brandId: brand.id, storeId };
        !body.phone && delete body.phone;
        !body.email && delete body.email;
        const { data } = await request.PUT(APIs.UPDATE_STORE, body);
        const updatedStores = stores.map(e => (e.id === data.id ? data : e));
        setStores(updatedStores);
        selectedStore.id === data.id && setSelectedStore(data);
        setShowAlert(true);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const viewAs = user.role === ROLES.ADMIN && !selectedStore.id ? ROLES.ADMIN : ROLES.MANAGER;

  return (
    <Fragment>
      <Card tag={Form} onSubmit={handleSubmit(onSubmitData)} className="theme-wizard">
        <CardHeader className="bg-light">
          <Nav className="justify-content-center">
            <NavItem>
              <NavLink
                className={classNames('font-weight-semi-bold', {
                  'done cursor-pointer': step > 1,
                  active: step === 1,
                })}
                // onClick={() => handleBackStep(1)}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">1{/* <FontAwesomeIcon icon="lock" /> */}</span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">{lang.stores.informations.title}</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames('font-weight-semi-bold', {
                  'done  cursor-pointer': step > 2,
                  active: step === 2,
                })}
                // onClick={() => handleBackStep(2)}
                // className="manger-link"
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">{/* <FontAwesomeIcon icon="user" /> */}2</span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">{lang.stores.location.title}</span>
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        {loading ? (
          <Loader />
        ) : (
          <CardBody className="fs--1 font-weight-normal px-md-6 pt-4 pb-3">
            {step === 1 && (
              <StoreInformation
                errors={errors}
                watch={watch}
                data={storeInfo}
                register={register}
                control={control}
                editMode
              />
            )}
            {step === 2 && (
              <StoreLocation
                errors={errors}
                watch={watch}
                storeLatlng={{ lat: storeInfo.lat, lng: storeInfo.lng }}
                setLatLng={latlng => setLatLng(latlng)}
              />
            )}
          </CardBody>
        )}
        <CardFooter className={classNames('px-md-6 bg-light d-flex')}>
          <Button color="primary" className="ml-auto px-5" type="submit" transform="down-1 shrink-4">
            {step === 1 ? lang.btn.next : lang.btn.save}
          </Button>
        </CardFooter>
      </Card>
      <Alert
        open={showAlert}
        onClick={() => {
          if (viewAs === ROLES.ADMIN) {
            history.push('/stores');
          } else {
            setStep(1);
            setShowAlert(false);
          }
        }}
        title={lang.stores.editSuccess}
      />
    </Fragment>
  );
};

export default EditStore;
