import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Card } from '../../components/common/Card';
import { BranchInformationSection } from '../../components/manager-information/branch_information_section';
import { PersonalInformationSection } from '../../components/manager-information/personal_information';
import AppContext from '../../context/Context';
import { isAR, lang } from '../../lang';
import brandPlaceHolderImage from '../../assets/img/store-placeholder.jpg';
import { Button } from 'reactstrap';
import { request, APIs } from '../../request';
import Avatar from '../../components/common/Avatar';
import { PAGE_TITLE_KEY } from '../../routes';
import { toast } from 'react-toastify';

const ManagerInformation = () => {
  const { brand, user, setUser, selectedStore, setStores, setSelectedStore, stores, setCurrentPage } = useContext(
    AppContext
  );
  const [branchInfo, setBranchInfo] = useState({});
  const [managerInfo, setManagerInfo] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchInfo = useCallback(async () => {
    // if (!user.mobile || !user.email) {
    const {
      data: { data },
    } = await request.GET(APIs.STAFF, { id: user?.id });
    setManagerInfo(data);
    // }
    setLoading(false);
  }, [user]);

  useEffect(() => {
    setCurrentPage(PAGE_TITLE_KEY.BRANCH_INFORMATION);
  }, [setCurrentPage]);

  useEffect(() => {
    fetchInfo();
  }, [fetchInfo]);

  const onEditButtonClick = useCallback(() => setEditMode(true), []);

  const onSaveButtonClick = async form => {
    setLoading(true);
    let storeId = selectedStore.id;
    try {
      // UPDATE STORE
      const { name, area } = branchInfo;
      const branchBody = {
        storeId,
        name,
        name_en: branchInfo.enName,
        area,
        lat: branchInfo.latLng.lat,
        lng: branchInfo.latLng.lng,
      };
      const { data } = await request.PUT(APIs.UPDATE_STORE, branchBody);
      const updatedStores = stores.map(e => (e.id === data.id ? data : e));
      setStores(updatedStores);
      selectedStore.id === data.id && setSelectedStore(data);

      // UPDATE MANAGER INFO
      const { data: manager_data } = await request.PUT(APIs.STAFF, { mobile: managerInfo.mobile, id: user.id });
      setUser({ ...user, ...manager_data });
      setEditMode(false);
      setLoading(false);
      toast.success(lang.managerInformation.updatedInformation);
    } catch (err) {
      toast.error(lang.defaultError);
      console.log(err);
    }
  };

  return (
    <Card className="h-100 py-3 px-4" fetching={loading}>
      <div style={{ flex: 1, justifyContent: 'flex-end', visibility: editMode ? 'hidden' : 'visible' }}>
        <Button className="appts-btn" outline color="primary" onClick={onEditButtonClick} disabled={editMode}>
          {lang.tableActions.edit}
        </Button>
      </div>
      <div className="d-flex justify-content-center align-items-center position-relative pb-5 mb-2 ">
        <div>
          <Avatar
            src={brand.logo?.file_url}
            placeholder_src={brandPlaceHolderImage}
            title={user.name}
            subtitle={selectedStore.id ? selectedStore[isAR ? 'name' : 'name_en'] : lang.admin}
          />
        </div>
      </div>
      <div className="d-flex flex-column flex-sm-row h-100">
        <div style={{ flex: 0.6 }}>
          <BranchInformationSection branch={selectedStore} editMode={editMode} setInfo={setBranchInfo} />
        </div>
        <div style={{ flex: 0.4 }}>
          <PersonalInformationSection
            mobile={managerInfo.mobile}
            email={managerInfo.email}
            editMode={editMode}
            setInfo={setManagerInfo}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end" style={{ visibility: !editMode ? 'hidden' : 'visible' }}>
        <Button className="appts-btn" outline color="primary" onClick={onSaveButtonClick} disabled={!editMode}>
          {lang.btn.save}
        </Button>
      </div>
    </Card>
  );
};

export default ManagerInformation;
