/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { isAR, lang } from "../../lang";
import iphone from "../../assets/img/yasser/download/u-yhh-iu@2x.png";
import app_store_en from "../../assets/img/badges/app_store_en.svg";
import google_play_en from "../../assets/img/badges/google_play_en.svg";
import app_store_ar from "../../assets/img/badges/app_store_ar.svg";
import google_play_ar from "../../assets/img/badges/google_play_ar.svg";

export default () => {
  return (
    <section id="download" className="landing-section download mb-0 pb-0">
      <div className="section-content mb-0 pb-0">
        <div className="main mb-0 pb-0 d-flex justify-content-around">
          <div>
            <img src={iphone} className="download-iphone" alt="ios-iphone" />
          </div>
          <div className="flex-grow-1 ml-xl-6 pl-xl-6 ml-md-3 pl-md-3 ml-sm-2 pl-sm-2 download-body">
            <h2 className="font-weight-bold mb-4 text-white">{lang.yasser.landing.download.title}</h2>
            <h5 className="text-white">{lang.yasser.landing.download.body}</h5>
            <div className="d-flex mt-6 badges">
              <a
                // href="https://play.google.com/store/apps/details?id=sa.gov.my.appointments"
                // target="_blank"
                rel="noopener noreferrer"
              >
                <img src={isAR ? google_play_ar : google_play_en} alt="play" />
              </a>
              <a
                // href="https://apps.apple.com/sa/app/gov-sa-national-platform/id1516850299"
                // target="_blank"
                rel="noopener noreferrer"
              >
                <img src={isAR ? app_store_ar : app_store_en} className="ml-xl-4 ml-md-2 ml-sm-2 play" alt="ios-app" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
