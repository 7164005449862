import { groupBy } from '.';
import { APPOINTMENT_STATUS } from '../constants';
import { lang } from '../lang';
import { date } from './date';

const isExpired = appt =>
  appt.status === APPOINTMENT_STATUS.PENDING && date.isBefore(date.createDate(appt.end_datetime), new Date());

const isNotArrived = appt =>
  appt.status === APPOINTMENT_STATUS.NOT_ARRIVED ||
  ((appt.status === APPOINTMENT_STATUS.CONFIRMED || appt.status === APPOINTMENT_STATUS.ACCEPTED) &&
    date.isBefore(date.createDate(appt.end_datetime), new Date()));

const formatActionDate = dateString => date.format(new Date(dateString.replace(/-/g, '/')), 'd/L/u - h:mm a');

const printFirstActionValue = appt => {
  if (
    appt.status === APPOINTMENT_STATUS.PENDING ||
    appt.status === APPOINTMENT_STATUS.EXPIRED ||
    appt.status === APPOINTMENT_STATUS.WALK_IN
  ) {
    return null;
  }

  if (
    [
      APPOINTMENT_STATUS.ACCEPTED,
      APPOINTMENT_STATUS.CANCELED,
      APPOINTMENT_STATUS.ARRIVED,
      APPOINTMENT_STATUS.CONFIRMED,
      APPOINTMENT_STATUS.NOT_ARRIVED,
    ].includes(appt.status)
  ) {
    const log = appt?.activities.find(l => l.log_name === APPOINTMENT_STATUS.ACCEPTED);
    return log ? formatActionDate(log.created_at) : lang.appointments.not_assigned;
  }

  const log = appt?.activities.find(a => a.properties?.status === APPOINTMENT_STATUS.REJECTED);
  return log ? formatActionDate(log.created_at) : lang.appointments.not_assigned;
};

const printLastActionValue = appt => {
  if ([APPOINTMENT_STATUS.CANCELED, APPOINTMENT_STATUS.ARRIVED].includes(appt.status)) {
    const log = appt?.activities.find(l => l.log_name === appt.status);
    return log ? formatActionDate(log.created_at) : lang.appointments.not_assigned;
  }
  return null;
};

// returns float number (duration in hr)
const calculateReposneTime = appt => {
  const now = new Date();
  const made_on = new Date(appt.activities?.find(log => log.log_name === 'created')?.created_at || now);
  const action_at = new Date(
    appt.activities?.find(log => log.log_name === 'accepted' || log.log_name === 'rejected')?.created_at || now
  );
  const appt_startDateTime = new Date(appt.start_datetime);
  const modified_at =
    date.isAfter(action_at, appt_startDateTime) && date.isBefore(made_on, action_at) ? appt_startDateTime : action_at;
  const waitingMins = Math.abs(date.differenceInMinutes(made_on, modified_at));
  const numOfDays = Math.floor(waitingMins / 60 / 24);
  const numOfHours = (waitingMins / 60) % 24;
  const res = numOfDays * 24 + numOfHours;
  return res;
};

const printResponseTime = appt => {
  // check if it was auto-accepted || there are no logs
  if (!appt?.activities.length) {
    return lang.appointments.not_assigned;
  } else {
    if (appt.status === APPOINTMENT_STATUS.WALK_IN) {
      return null;
    }

    const accepted_log = appt?.activities.find(l => l.log_name === 'created');
    if (accepted_log?.properties['auto-accept']) {
      return lang.appointments.auto_accepted;
    }
  }

  // check if  expired || pending
  if (appt.status === APPOINTMENT_STATUS.EXPIRED || isExpired(appt)) {
    return `${lang.appointments.no_response}!`;
  } else if (appt.status === APPOINTMENT_STATUS.PENDING) {
    return `${lang.appointments.pending}`;
  }

  // else, compute response time :(
  const response_time = calculateReposneTime(appt);
  return formatResponseTime(response_time);
};

const formatResponseTime = val => {
  // 'val' is duration in hours (float number)
  const hours = Math.floor(val) ? `${Math.floor(val)} ${lang.dashboardItems.hr}` : '';
  return `${hours} ${Math.round((val - Math.floor(val)) * 60)} ${lang.dashboardItems.min}`;
};

export const apptHelpers = {
  isExpired,
  isNotArrived,
  printFirstActionValue,
  printLastActionValue,
  formatActionDate,
  printResponseTime,
  calculateReposneTime,
};

export const countApptsByStatus = (storeAppts, status = null, previous = false) => {
  const groupedApptsByStatuses = groupBy(storeAppts, 'status');
  switch (status) {
    case 'accepted':
      const accepted_count =
        groupedApptsByStatuses?.['accepted']?.filter(
          appt => new Date(appt.end_datetime) >= date.setStartOfDay(new Date()) || previous
        ).length || 0;
      const confirmed_count =
        groupedApptsByStatuses?.['confirmed']?.filter(
          appt => new Date(appt.end_datetime) >= date.setStartOfDay(new Date()) || previous
        ).length || 0;
      const arrived_count = groupedApptsByStatuses?.['arrived']?.length || 0;
      return accepted_count + confirmed_count + arrived_count;
    case 'rejected':
      return groupedApptsByStatuses?.['rejected']?.length || 0;
    case 'canceled':
      return groupedApptsByStatuses?.['canceled']?.length || 0;
    case 'noAction':
      return (
        (groupedApptsByStatuses?.pending || [])?.filter(
          appt => date.isBefore(date.createDate(appt.end_datetime), new Date()) || previous
        ).length || 0
      );
    default:
      return storeAppts?.length || 0;
  }
};

export const getDayAppts = raw_appts => {
  return raw_appts.filter(appt => date.isToday(new Date(appt.start_datetime)));
};

export const getWeekAppts = raw_appts => {
  return raw_appts.filter(appt => {
    return date.diff(new Date().getDate(), new Date(appt.start_datetime).getDate()) <= 7;
  });
};

export const getMonthAppts = raw_appts => {
  return raw_appts.filter(appt => {
    return date.diff(new Date().getDate(), new Date(appt.start_datetime).getDate()) <= 31;
  });
};

export const getPreviousDayAppts = raw_appts => {
  return raw_appts.filter(appt => {
    const diff = date.diff(new Date().getDate(), new Date(appt.start_datetime).getDate());
    return diff === 1;
  });
};

export const getPreviousWeekAppts = raw_appts => {
  return raw_appts.filter(appt => {
    const diff = date.diff(new Date().getDate(), new Date(appt.start_datetime).getDate());
    return diff > 7 && diff <= 14;
  });
};

export const getPreviousMonthAppts = raw_appts => {
  return raw_appts.filter(appt => {
    const diff = date.diff(new Date().getDate(), new Date(appt.start_datetime).getDate());
    return diff > 31 && diff <= 62;
  });
};

export const prepareBranchActivity = (currentAppts = [], previousAppts = [], raw_stores) => {
  // group updated_appts by store name
  const index = 'store.id';
  const groupedCurrentApptsByStore = groupBy(currentAppts, index);
  const groupedPreviousApptsByStore = groupBy(previousAppts, index);

  // calculate current total number of appts and each status total number for all stores
  const updated_stores = raw_stores.map(s => ({
    name_ar: s.name,
    name_en: s.name_en,
    // Current time period
    totalAppts: countApptsByStatus(groupedCurrentApptsByStore[s.id]),
    totalAccepted: countApptsByStatus(groupedCurrentApptsByStore[s.id], 'accepted'),
    totalRejected: countApptsByStatus(groupedCurrentApptsByStore[s.id], 'rejected'),
    totalCanceled: countApptsByStatus(groupedCurrentApptsByStore[s.id], 'canceled'),
    totalNoAction: countApptsByStatus(groupedCurrentApptsByStore[s.id], 'noAction'),

    // Previous time period
    totalPreviousAppts: countApptsByStatus(groupedPreviousApptsByStore[s.id]),
    totalPreviousAccepted: countApptsByStatus(groupedPreviousApptsByStore[s.id], 'accepted', true),
    totalPreviousRejected: countApptsByStatus(groupedPreviousApptsByStore[s.id], 'rejected'),
    totalPreviousCanceled: countApptsByStatus(groupedPreviousApptsByStore[s.id], 'canceled'),
    totalPreviousNoAction: countApptsByStatus(groupedPreviousApptsByStore[s.id], 'noAction'),
  }));

  return updated_stores;
};
