import React, { useCallback, useContext, useEffect } from 'react';
import { ROLES } from '../../constants';
import AppContext from '../../context/Context';
import { isEN } from '../../lang';
import { APIs, request } from '../../request';
import { LogoutIcon, KSAIcon, USAIcon, AuthHelpers, NotificationIcon } from '../../utils';
import { Link } from 'react-router-dom';

const TopNavRightSideNavItem = () => {
  const { setBrand, user, toggleLang, clearContext } = useContext(AppContext);

  const fetchBrand = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await request.GET(APIs.BRAND);
      setBrand(data[0]);
    } catch (err) {
      console.log(err);
    }
  }, [setBrand]);

  const handleLogout = () => {
    AuthHelpers.logout();
    clearContext();
  };

  useEffect(() => {
    user.role === ROLES.ADMIN && fetchBrand();
  }, [fetchBrand, user]);

  return (
    <div className="ml-auto d-flex align-items-center">
      <Link to={'/notifications'}>
        <NotificationIcon />
      </Link>
      <button
        id="changeLangBtn"
        className="mx-3 bg-white d-flex justify-content-center align-items-center"
        onClick={toggleLang}
      >
        {isEN ? <KSAIcon height={13} /> : <USAIcon height={13} />}
      </button>
      <button id="logoutBtn" onClick={handleLogout} to="/auth/logout">
        <LogoutIcon height={20} />
      </button>
    </div>
  );
};

export default TopNavRightSideNavItem;
