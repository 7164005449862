import React, { useContext, useState } from 'react';
import { CardBody, Card, Form, CardFooter, Button } from 'reactstrap';
import AddForm from '../../components/services/Form';
import { lang } from '../../lang';
import AppContext from '../../context/Context';
import { request, APIs } from '../../request';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

export default () => {
  const history = useHistory();
  const { selectedStore } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors, watch, setValue, control } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const onSubmitData = async form => {
    try {
      setLoading(true);
      const add_service_body = {
        store_id: selectedStore.id,
        name_en: form.service_name_en,
        name_ar: form.service_name_ar,
        capacity: form.capacity,
        duration: form.duration.value,
        pre_msg: form.pre_msg,
        post_msg_ar: form.post_msg_ar,
        post_msg_en: form.post_msg_en,
      };
      !add_service_body.pre_msg && delete add_service_body.pre_msg;
      !add_service_body.post_msg_ar && delete add_service_body.post_msg_ar;
      !add_service_body.post_msg_en && delete add_service_body.post_msg_en;
      const { data } = await request.POST(APIs.SERVICES, add_service_body);
      const service = data.data;
      const shifts = form.shifts ? form.shifts.map(shift => shift.value).filter(id => id) : [];
      if (shifts.length) {
        await request.PUT(APIs.SERVICES, { shifts, service_id: service.id });
      }
      setLoading(false);
      toast.success(lang.services.addService.success);
      history.push('/services');
    } catch (err) {
      console.log(err);
      toast.error(lang.defaultError);
    }
  };

  return (
    <div className="schedule">
      <>
        <Card tag={Form} onSubmit={handleSubmit(onSubmitData)}>
          <CardBody>
            <div className="tab-content" id="myTabContent">
              <AddForm errors={errors} watch={watch} register={register} control={control} setValue={setValue} />
            </div>
          </CardBody>
          <CardFooter className={'px-md-6 bg-light d-flex'}>
            <Button
              color="primary"
              className="ml-auto px-5"
              type="submit"
              disabled={loading}
              transform="down-1 shrink-4"
            >
              {lang.btn.save}
            </Button>
          </CardFooter>
        </Card>
      </>
    </div>
  );
};
