import React, { Fragment } from "react";
import WizardInput from "../auth/wizard/WizardInput";
import { lang } from "../../lang";
import { range, prepareShiftTimeLabel } from "../../utils";

const StoreInformation = ({ register, errors, data = {}, control, editMode }) => {
  const DAYS_OPTIONS = [
    { label: lang.days.sun, value: "sunday" },
    { label: lang.days.mon, value: "monday" },
    { label: lang.days.tue, value: "tuesday" },
    { label: lang.days.wed, value: "wednesday" },
    { label: lang.days.thu, value: "thursday" },
    { label: lang.days.fri, value: "friday" },
    { label: lang.days.sat, value: "saturday" }
  ];

  const DAFAULT_START_TIME = { label: "9:00AM", value: "09:00" };
  const DAFAULT_END_TIME = { label: "02:00PM", value: "14:00" };

  const TIME = range(48).map(index => {
    let startIndex = 10 + index;
    startIndex > 47 && (startIndex = index - 38);
    const hours = `${Math.floor(startIndex / 2)}`.padStart(2, "0");
    const min = startIndex % 2 ? "30" : "00";
    const time = `${hours}:${min}`;
    const label = prepareShiftTimeLabel(time);
    return { label, value: time };
  });

  return (
    <Fragment>
      <WizardInput
        label={`${lang.stores.informations.name}*`}
        // placeholder="Name"
        name="name"
        id="name"
        defaultValue={data.name}
        errors={errors}
        innerRef={register({
          required: lang.requiredField,
          pattern: {
            value: /^([\u0600-\u06FF0-9]+\s)*[\u0600-\u06FF0-9]*$/,
            message: lang.validationMessage.enterArabicLetter
          }
        })}
      />
      <WizardInput
        label={`${lang.stores.informations.name_en}*`}
        // placeholder="ID"
        name="name_en"
        id="name_en"
        defaultValue={data.name_en}
        errors={errors}
        innerRef={register({
          required: lang.requiredField,
          pattern: {
            value: /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]*$/,
            message: lang.validationMessage.enterEnglishLetter
          }
        })}
      />
      <WizardInput
        label={`${lang.stores.informations.area}*`}
        // placeholder="600m"
        defaultValue={data.area}
        name="area"
        // id="area"
        errors={errors}
        innerRef={register({
          required: lang.requiredField,
          pattern: {
            value: /^[0-9]+$/,
            message: lang.validationMessage.entervalidNumber
          }
        })}
      />

      {editMode ? null : (
        <>
          <WizardInput
            label={`${lang.shift.days}*`}
            id="days"
            name="days"
            customType="react-select"
            options={DAYS_OPTIONS}
            control={control}
            errors={errors}
            placeholder=""
            rules={{ required: lang.requiredField }}
            defaultValue={data.days}
            closeMenuOnSelect={false}
            isMulti
          />
          <div className="d-inline-block col-12 col-md-6 pl-0 pr-4">
            <WizardInput
              label={`${lang.shift.start}*`}
              id="start"
              name="start"
              customType="react-select"
              options={TIME}
              control={control}
              errors={errors}
              placeholder=""
              rules={{ required: lang.requiredField }}
              defaultValue={DAFAULT_START_TIME}
            />
          </div>
          <div className="d-inline-block col-12 col-md-6 pl-2 pr-0">
            <WizardInput
              label={`${lang.shift.end}*`}
              id="end"
              name="end"
              customType="react-select"
              options={TIME}
              control={control}
              errors={errors}
              rules={{ required: lang.requiredField }}
              defaultValue={DAFAULT_END_TIME}
            />
          </div>
        </>
      )}
      <div className="d-inline-block col-12 col-md-6 pl-0 pr-4">
        <WizardInput
          label={`${lang.stores.informations.email}`}
          // placeholder="Phone number"
          defaultValue={data.email}
          name="email"
          id="email"
          // type="email"
          errors={errors}
          innerRef={register({
            required: false,
            pattern: {
              value: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/,
              message: lang.validationMessage.entervalidEmail
            }
          })}
        />
      </div>
      <div className="d-inline-block col-12 col-md-6 pl-2 pr-0">
        <WizardInput
          label={`${lang.stores.informations.phoneNum}`}
          // placeholder="Phone number"
          defaultValue={data.phone}
          name="phone"
          id="phone"
          className="mobile-num"
          errors={errors}
          innerRef={register({
            required: false,
            pattern: {
              value: /^[0-9]+$/,
              message: lang.validationMessage.entervalidNumber
            }
          })}
        />
      </div>
    </Fragment>
  );
};

export default StoreInformation;
