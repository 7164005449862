import React from 'react';
import featureThree from '../../assets/img/yasser/features/feature-1.svg';
import featureTwo from '../../assets/img/yasser/features/feature-2.svg';
import featureOne from '../../assets/img/yasser/features/feature-3.svg';
import { lang } from '../../lang';
import bg from '../../assets/img/yasser/features/01.svg';

const Feature = ({ image, title, body, className }) => {
  return (
    <div className={`${className} col-md-12 col-sm-12 mt-3 mb-4 mb-xl-6 `} align="center">
      <div className="w-75">
        <div className="py-3">
          <img src={image} className="feature-img" alt="tamkeen asset" />
        </div>
        <h3 className="text-center mt-2">{title}</h3>
        <h4 className="text-center mt-4">{body}</h4>
      </div>
    </div>
  );
};

function Features() {
  return (
    <section
      id="appointments"
      className="landing-section features-page d-flex justify-content-center align-items-center container"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="section-content">
        <div className="col">
          <div className="row-12">
            <h1 className="text-center mb-3">{lang.landing.features}</h1>
            <div className="col">
              <div className="row">
                <Feature
                  className="col-lg-4"
                  image={featureOne}
                  title={lang.yasser.landing.featureOne.title}
                  body={lang.yasser.landing.featureOne.body}
                />
                <Feature
                  className="col-lg-4"
                  image={featureTwo}
                  title={lang.yasser.landing.featureTwo.title}
                  body={lang.yasser.landing.featureTwo.body}
                />
                <Feature
                  className="col-lg-4"
                  image={featureThree}
                  title={lang.yasser.landing.featureThree.title}
                  body={lang.yasser.landing.featureThree.body}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
