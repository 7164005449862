import React, { useContext, useState, Fragment } from "react";
import { Card, CardBody, CardFooter, CardHeader, Form, Nav, NavItem, NavLink, Button } from "reactstrap";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import AppContext from "../../context/Context";
import ButtonIcon from "../../components/common/ButtonIcon";
import { useHistory } from "react-router-dom";
import { lang } from "../../lang";
import { ROLES } from "../../constants";
import { request, APIs } from "../../request";
import GuardInformations from "../../components/guards/GuardInformation";
import GuardCredentials from "../../components/guards/GuardCredentials";
import { toast } from "react-toastify";

const AddGuard = () => {
  const history = useHistory();
  const { isRTL, selectedStore } = useContext(AppContext);
  const [step, setStep] = useState(1);
  const [fields, setField] = useState({});

  const { handleSubmit, errors, watch, register } = useForm();

  const onSubmitData = async form => {
    if (step < 2) {
      setField(form);
      setStep(step + 1);
    } else if (step === 2) {
      try {
        const body = {
          ...fields,
          email: form.guard_email,
          password: form.guard_password,
          manageable_id: selectedStore.id,
          manageable_type: "store",
          role: ROLES.GUARD
        };
        await request.POST(APIs.STAFF, body);
        toast.success(lang.guards.addGuard.success);
        history.push("/guards");
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <Fragment>
      <Card tag={Form} onSubmit={handleSubmit(onSubmitData)} className="theme-wizard">
        <CardHeader className="bg-light">
          <Nav className="justify-content-center">
            <NavItem>
              <NavLink
                className={classNames("font-weight-semi-bold", {
                  "done cursor-pointer": step > 1,
                  active: step === 1
                })}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">1</span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">{lang.guards.addGuard.info}</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames("font-weight-semi-bold", {
                  "done  cursor-pointer": step > 2,
                  active: step === 2
                })}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">2</span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">{lang.guards.addGuard.credentials}</span>
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody className="fs--1 font-weight-normal px-md-6 pt-4 pb-3">
          {step === 1 && <GuardInformations errors={errors} watch={watch} register={register} />}
          {step === 2 && <GuardCredentials errors={errors} watch={watch} register={register} />}
        </CardBody>
        <CardFooter className={classNames("px-md-6 bg-light", { "d-none": step === 3, " d-flex": step < 3 })}>
          <ButtonIcon
            color="link"
            icon={isRTL ? "chevron-right" : "chevron-left"}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames("px-0 font-weight-semi-bold", { "d-none": step === 1 })}
            onClick={() => {
              setStep(step - 1);
            }}
          >
            {lang.btn.back}
          </ButtonIcon>
            { step === 1 ? 
                <ButtonIcon
                color="primary"
                className="ml-auto px-5"
                type="submit"
                icon={isRTL ? "chevron-left" : "chevron-right"}
                iconAlign="right"
                transform="down-1 shrink-4"
              >
               {lang.btn.next}
              </ButtonIcon>
              :
              <Button color="primary" className="ml-auto px-5" type="submit" transform="down-1 shrink-4">
               {lang.btn.save}
              </Button>  
            }
        </CardFooter>
      </Card>
    </Fragment>
  );
};

export default AddGuard;
