import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Alert, CardBody, Card } from 'reactstrap';
import classNames from 'classnames';
import Loader from '../common/Loader';
import AppContext from '../../context/Context';
import CardTabs from '../common/CardTabs';
import { Holiday } from './Holiday';
import { lang } from '../../lang';

export default () => {
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const { selectedStore, setSelectedStore } = useContext(AppContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setHolidays(selectedStore.configuration?.['holidays'] || [{ name: `Holiday ${holidays.length + 1}` }]);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addHolidayTab = () => {
    setHolidays([...holidays, { name: `Holiday ${holidays.length + 1}` }]);
    setTab(holidays.length);
  };

  const deleteHoliday = index => {
    const updated = holidays.filter((_, i) => i !== index);
    setHolidays(updated);
    setTab(0);
    updateSelectedStore(updated);
  };

  const updateHolidays = updated => {
    setHolidays(updated);
    updateSelectedStore(updated);
  };

  const updateSelectedStore = updated => {
    setSelectedStore({ ...selectedStore, configuration: { ...selectedStore.configuration, holidays: updated } });
  };

  const prepareHolidaysTabs = useCallback(() => {
    return holidays.map(holiday => ({ name: holiday.name, id: holiday.id, hideCloseButton: !!holiday.id }));
  }, [holidays]);

  const onTabChange = useCallback(index => {
    setTab(index);
  }, []);

  return (
    <div className="schedule">
      {loading ? (
        <Loader />
      ) : (
        <>
          <CardTabs
            tabs={prepareHolidaysTabs()}
            closeTab={deleteHoliday}
            addTab={addHolidayTab}
            onTabChange={onTabChange}
            selectedTab={tab}
          />
          <Card>
            <CardBody>
              <div className="tab-content" id="myTabContent">
                {holidays.length ? (
                  holidays.map((holiday, index) => (
                    <Holiday
                      key={holiday.name + index}
                      data={holiday}
                      holidays={holidays}
                      holidayNumber={holidays.length}
                      deleteHoliday={() => deleteHoliday(index)}
                      updateHolidays={updateHolidays}
                      className={classNames('tab-pane fade', { show: tab === index, active: tab === index })}
                    />
                  ))
                ) : (
                  <Alert color="info" className="mb-0">
                    {lang.holiday.noHolidays}
                  </Alert>
                )}
              </div>
            </CardBody>
          </Card>
        </>
      )}
    </div>
  );
};
