import { lang } from './lang';
import { ROLES } from './constants';
import {
  IconCalendar,
  IconCalendarCheck,
  IconCity,
  IconCog,
  IconDocument,
  IconEnvelopeOpen,
  IconHome,
  IconShapes,
  IconUserShield,
  IconUserTie,
} from './utils';

export const PAGE_TITLE_KEY = {
  DASHBOARD: 'dashboard',
  BOOKINGS: 'appointments',
  SCHEDULE: 'schedule',
  MODERATOR: 'moderatorManagments',
  GUARD: 'guardianManagment',
  STORES: 'storesManagment',
  DOCUMENTS: 'documents',
  SERVICES: 'services',
  CONFIGURATION: 'configuration',
  INVITATIONS: 'invitations',
  NOTIFICATIONS: 'notifications',
  BRANCH_INFORMATION: 'branch_information',
};

export const home = () => ({
  key: PAGE_TITLE_KEY.DASHBOARD,
  name: lang.navMenu.dashboard,
  to: '/dashboard',
  exact: false,
  Icon: IconHome,
});

export const booking = () => ({
  key: PAGE_TITLE_KEY.BOOKINGS,
  name: lang.navMenu.appointments,
  to: '/appointments',
  exact: true,
  Icon: IconCalendarCheck,
});

export const schedule = () => ({
  key: PAGE_TITLE_KEY.SCHEDULE,
  name: lang.navMenu.schedule,
  to: '/schedule',
  exact: true,
  Icon: IconCalendar,
});

const moderatorManagment = () => ({
  key: PAGE_TITLE_KEY.MODERATOR,
  to: '/moderators',
  name: lang.navMenu.moderatorManagments,
  Icon: IconUserTie,
  exact: true,
});

const guardManagment = () => ({
  key: PAGE_TITLE_KEY.GUARD,
  to: '/guards',
  name: lang.navMenu.guardianManagment,
  exact: true,
  Icon: IconUserShield,
});

const documents = () => ({
  key: PAGE_TITLE_KEY.DOCUMENTS,
  name: lang.navMenu.documents,
  to: '/user-manuals',
  Icon: IconDocument,
  exact: true,
});

const storesManagment = () => ({
  key: PAGE_TITLE_KEY.STORES,
  name: lang.navMenu.storesManagment,
  to: '/stores',
  exact: true,
  Icon: IconCity,
});

const servicesManagment = () => ({
  key: PAGE_TITLE_KEY.SERVICES,
  to: '/services',
  name: lang.navMenu.services,
  Icon: IconShapes,
  exact: true,
});

const configuration = () => ({
  key: PAGE_TITLE_KEY.CONFIGURATION,
  to: '/configuration',
  name: lang.navMenu.configuration,
  Icon: IconCog,
  exact: true,
});

const invitationsManagment = () => ({
  to: '/invitations',
  name: lang.navMenu.invitations,
  Icon: IconEnvelopeOpen,
  exact: true,
});

const prepareAdminRoutes = () => [home(), storesManagment(), documents()];

const prepareManagerRoutes = () => [
  home(),
  booking(),
  schedule(),
  servicesManagment(),
  moderatorManagment(),
  guardManagment(),
  invitationsManagment(),
  configuration(),
];

export default (viewAs = ROLES.MANAGER) => (viewAs === ROLES.ADMIN ? prepareAdminRoutes() : prepareManagerRoutes());
