import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { lang } from '../../lang';
import { Link } from 'react-router-dom';
import { IconEllipses } from '../../utils';

const TableActionButton = ({ id, email, onDeleteClicked, onResetClicked }) => (
  <UncontrolledDropdown className="position-static">
    <DropdownToggle color="link" size="sm" className="text-600 btn-reveal">
      <IconEllipses className="fs--1" />
    </DropdownToggle>
    <DropdownMenu right className="border py-2">
      <Link to={`/moderators/edit/${id}`}>
        <DropdownItem>{lang.tableActions.edit}</DropdownItem>
      </Link>

      <DropdownItem divider />
      <DropdownItem onClick={() => onResetClicked(email)}>{lang.tableActions.resetAccount}</DropdownItem>

      <DropdownItem divider />
      <DropdownItem className="text-danger" onClick={() => onDeleteClicked(id)}>
        {lang.tableActions.delete}
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

export default TableActionButton;
