import React, { useCallback, useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import { isAR, lang } from '../../lang';
import WizardInput from '../../components/auth/wizard/WizardInput';
import { useContext } from 'react';
import AppContext from '../../context/Context';
import { APIs, request } from '../../request';

export default ({ className, errors, register, control, data = {}, setValue }) => {
  const { selectedStore } = useContext(AppContext);
  const [loadingServices, setLoadingServices] = useState(false);
  const [servicesOptions, setServicesOptions] = useState([]);

  const fetchServices = useCallback(async () => {
    setLoadingServices(true);
    const { data } = await request.GET(APIs.STORE_SERVICES, { store_id: selectedStore.id });
    const servicesData = data.data.map(service => ({
      value: service.id,
      label: service[`name_${isAR ? 'ar' : 'en'}`],
    }));

    setServicesOptions(servicesData);
    setLoadingServices(false);
  }, [selectedStore]);

  useEffect(() => {
    selectedStore.id && fetchServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <article className={`p-3 ${className} `}>
      <section className="p-0">
        <div className="row">
          <div className="d-inline-block col-lg-4 col-md-6 col-12">
            <Label for="subject">{lang.invitations.labels.subject}</Label>
            <small className="d-inline-block text-danger mx-1">*</small>
            <WizardInput
              id="subject"
              name="subject"
              errors={errors}
              defaultValue={data.service_name_en}
              innerRef={register({
                required: lang.requiredField,
              })}
            />
          </div>
          <div className="d-inline-block col-lg-4 col-md-6 col-12">
            <Label for="service">{lang.invitations.labels.service}</Label>
            <small className="d-inline-block text-danger mx-1">*</small>
            <WizardInput
              id="service"
              name="service"
              customType="react-select"
              options={servicesOptions}
              control={control}
              errors={errors}
              rules={{ required: lang.requiredField }}
              placeholder={loadingServices ? lang.invitations.labels.loading_services : ''}
              isDisabled={!selectedStore.id}
            />
          </div>
        </div>
        <div className="mt-4 row">
          <div className="d-inline-block col-lg-4 col-md-6 col-12">
            <Label for="date">{lang.invitations.labels.date}</Label>
            <small className="d-inline-block text-danger mx-1">*</small>
            <WizardInput
              customType="datetime"
              id="date"
              name="date"
              errors={errors}
              defaultValue={data.capacity}
              rules={{ required: lang.requiredField }}
              minDate={Date.now()}
              innerRef={register({
                required: lang.requiredField,
              })}
              popperPlacement="bottom-end"
              popperModifiers={{
                flip: {
                  behavior: ['bottom'], // don't allow it to flip to be above
                },
                preventOverflow: {
                  enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                hide: {
                  enabled: false, // turn off since needs preventOverflow to be enabled
                },
              }}
            />
          </div>
          <div className="d-inline-block col-lg-4 col-md-6 col-12">
            <Label for="time">{lang.invitations.labels.time}</Label>
            <small className="d-inline-block text-danger mx-1">*</small>
            <WizardInput
              id="time"
              name="time"
              customType="time-picker"
              control={control}
              errors={errors}
              defaultValue={data.capacity}
              innerRef={register({
                required: lang.requiredField,
              })}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="d-inline-block col-lg-4 col-md-6 col-12">
            <Label for="location">{lang.invitations.labels.location}</Label>
            <small className="d-inline-block text-danger mx-1">*</small>
            <WizardInput
              id="location"
              type="textarea"
              rows={5}
              name="location"
              errors={errors}
              innerRef={register({
                required: lang.requiredField,
              })}
            />
          </div>
          <div className="d-inline-block col-lg-4 col-md-6 col-12">
            <Label for="description">{lang.invitations.labels.comment}</Label>
            <WizardInput id="description" type="textarea" name="description" errors={errors} rows={5} />
          </div>
        </div>
      </section>
    </article>
  );
};
