import React, { Fragment, useCallback, useContext } from 'react';
import useSWR from 'swr';
import { Col, Row } from 'reactstrap';
import { CongestionHours } from '../../components/manager_dashboard/CongestionHours';
import { VacanciesGraph } from '../../components/manager_dashboard/VacanciesGraph';
import { APIs, prepareURL, request } from '../../request';
// import { toast } from 'react-toastify';
// import { lang } from '../../lang';
import AppContext from '../../context/Context';
import { AppointmentsStatuses } from '../../components/manager_dashboard/DailyApptsStatuses';
import { ApptStatuses } from '../../components/manager_dashboard/ApptStatuses';
import Flex from '../../components/common/Flex';
import { REVALIDATE_TIME } from '../../constants';

const DashboardAlt = () => {
  const { selectedStore } = useContext(AppContext);
  let fetchingServices = false;
  let fetchingAppts = false;

  const fetchServices = useCallback(async (URL, store_id) => {
    try {
      if (store_id) {
        const {
          data: { data: services },
        } = await request.GET(URL);

        return services;
      }
      return [];
    } catch (e) {
      // toast.error(lang.defaultError);
      console.log({ e });
    }
  }, []);

  const fetchAppts = useCallback(async (URL, store_id, per_page) => {
    // managers should have store_id selected while owner could have overview on stores.
    try {
      if (store_id) {
        const {
          data: { data: appts },
        } = await request.GET(URL, { per_page });

        return appts;
      }
      return [];
    } catch (e) {
      // toast.error(lang.defaultError);
      console.log({ e });
    }
  }, []);

  const { data: appts } = useSWR(
    [prepareURL(APIs.APPOINTMENTS, { store_id: selectedStore.id, per_page: 500 }), selectedStore.id],
    fetchAppts,
    {
      refreshInterval: REVALIDATE_TIME,
    }
  );
  const { data: services } = useSWR(
    [prepareURL(APIs.STORE_SERVICES, { store_id: selectedStore.id }), selectedStore.id],
    fetchServices,
    {
      refreshInterval: REVALIDATE_TIME,
    }
  );

  !appts ? (fetchingAppts = true) : (fetchingAppts = false);
  !services ? (fetchingServices = true) : (fetchingServices = false);

  return (
    <Fragment>
      <div className="space-pb h-30">
        <AppointmentsStatuses loading={fetchingAppts} data={appts || []} className="h-100" />
      </div>
      <Row noGutters className="h-70">
        <Flex className="flex-column h-100 col-6 space-hlf-pr">
          <div className="space-hlf-pb h-60">
            <VacanciesGraph
              loading={fetchingAppts || fetchingServices}
              services={services || []}
              appts={appts || []}
              className="overflow-auto f-1 h-100"
            />
          </div>
          <div className="space-hlf-pt h-40">
            <CongestionHours loading={fetchingAppts} data={appts || []} className="h-100" />
          </div>
        </Flex>
        <Col className="col-6 space-hlf-pl">
          <ApptStatuses loading={fetchingAppts} className="h-100" data={appts || []} />
        </Col>
      </Row>
    </Fragment>
  );
};

export default DashboardAlt;
