/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Input } from 'reactstrap';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { range } from '../../utils';
import classNames from 'classnames';
import dayjs from 'dayjs';
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
var isToday = require('dayjs/plugin/isToday');
dayjs.extend(isSameOrAfter);
dayjs.extend(isToday);
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
dayjs.extend(isSameOrBefore);

export const TimePicker = ({ style, hourLabel = 'Hour', minuteLabel = 'Minute', control, className, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');

  const [hour, setHour] = useState(dayjs().format('HH'));
  const [minute, setMinute] = useState('');
  const [dayTime, setDayTime] = useState('');
  const [daySelected, setDaySelected] = useState(false);

  const show = useCallback(() => control.getValues().date && setOpen(true), []);
  const hide = useCallback(() => setOpen(false), []);

  const ref = useRef();
  useOnClickOutside(ref, hide);

  // const hours = useMemo(() => range(0, 12).map(val => (!val ? '12' : `${val}`.padStart(2, 0))), []);
  const rangeHours = useMemo(() => {
    const hoursFormats = [
      '00',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
    ];
    const result = [];
    if (!!control.getValues().date) {
      // console.log('--date ' + control.getValues().date);
      hoursFormats.map(i => {
        const controlDate = control.getValues().date + ' ' + i;
        const comparedDate = dayjs(controlDate).format('YYYY-MM-DD HH:mm');
        const isBefore = dayjs().isSameOrBefore(comparedDate, 'hour');
        if (isBefore) {
          result.push({ disabled: true, value: i });
        } else {
          result.push({ value: i });
        }
      });
    }

    return result;
  }, [control.getValues().date]);

  const MinutesFormats = useMemo(() => range(0, 60, 5).map(val => `${val}`.padStart(2, 0)), []); // { '00': '' }
  const rangeMinutes = useMemo(() => {
    const MinResult = [];
    if (!!control.getValues().date) {
      MinutesFormats.map(minute => {
        const selectedDay = dayjs(control.getValues().date).format('YYYY-MM-DD');
        const isSelectedDayToday = dayjs(selectedDay).isToday();
        if (isSelectedDayToday && !!hour) {
          const selectedHour = hour;
          const comparedMinutes = dayjs(selectedDay).format(`YYYY-MM-DD ${selectedHour}:${minute}`);
          const isBefore = dayjs().isBefore(comparedMinutes, 'm');
          if (isBefore) {
            MinResult.push({ disabled: true, value: minute });
          } else {
            MinResult.push({ value: minute });
          }
        } else {
          MinResult.push({ value: minute, disabled: true });
        }
      });
    }

    return MinResult;
  }, [control.getValues().date, hour]);

  // console.log(rangeMinutes);
  // const minutes = useMemo(() => range(0, 60, 5).map(val => `${val}`.padStart(2, 0)), []);

  useEffect(() => {
    if (hour && minute) {
      const dateFormat = dayjs(`${dayjs().format('YYYY-MM-DD')} ${hour}:${minute}`).format('HH:mm');
      setValue(dateFormat);
      hide();
    }
  }, [hour, minute, dayTime, hide]);

  return (
    <div
      ref={ref}
      className={`react-timepicker react-timepicker--${open ? 'open' : 'hide'} position-relative`}
      onFocus={show}
    >
      <Input value={value} readOnly className={`${className} react-timepicker-input`} {...rest} />
      <div className="react-timepicker-container">
        <div className="react-timepicker-main">
          <h6 className="react-timepicker-subtitle">{hourLabel}</h6>
          <div className="react-timepicker-hours">
            <div className="react-timepicker-row flex-wrap justify-content-start">
              {rangeHours.map(({ value, disabled }) => (
                <div className="col-2 col-sm-1">
                  <button
                    key={value}
                    className={classNames('react-timepicker-cell', {
                      'react-timepicker-cell--selected': hour === value,
                    })}
                    onClick={() => setHour(value)}
                    style={{
                      opacity: !disabled ? '.5' : '1',
                    }}
                    disabled={!disabled}
                  >
                    {value}
                  </button>
                </div>
              ))}
            </div>
          </div>
          <h6 className="react-timepicker-subtitle">{minuteLabel}</h6>
          <div className="react-timepicker-minutes">
            <div className="react-timepicker-row flex-wrap justify-content-start">
              {rangeMinutes.map(({ value, disabled }) => (
                <div className="col-2 col-sm-1">
                  <button
                    dir="ltr"
                    key={value}
                    className={classNames('react-timepicker-cell', {
                      'react-timepicker-cell--selected': minute === value,
                    })}
                    onClick={() => setMinute(value)}
                    style={{
                      opacity: !disabled ? '.5' : '1',
                    }}
                    disabled={!disabled}
                  >
                    :{value}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
