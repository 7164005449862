import React from 'react';
import { lang } from '../../lang';
import gov from '../../assets/img/yasser/how/government.svg';
import calendar from '../../assets/img/yasser/how/clndr.svg';
import qr from '../../assets/img/yasser/how/qr.svg';
import queue from '../../assets/img/yasser/how/queue.svg';
import iphone from '../../assets/img/yasser/how/iphone@3x.png';

const Feature = ({ image, title, body, className }) => {
  return (
    <div className={`${className} how-desc col-md-6 col-sm-12 mt-3 mb-4 mb-xl-6 `} align="center">
      <div className="how-sect">
        <div className="">
          <img src={image} className="feature-img float-left mb-3" alt="tamkeen asset" />
        </div>
        <div className="how-text">
          <h4 className="text-left mt-2 how-title">{title}</h4>
          <h4 className="text-left mt-3 how-body">{body}</h4>
        </div>
      </div>
    </div>
  );
};

function How() {
  return (
    <section
      id="how"
      className="landing-section how-page d-flex justify-content-center align-items-center container my-4"
    >
      <div className="section-content">
        <div className="col">
          <div className="row-12">
            <h1 className="text-center mb-3">{lang.yasser.landing.header.how}</h1>
            <div className="d-flex align-items-center mt-6 no-gutters">
              <div className="col-lg-8 col-md-4 col-sm-6">
                <div className="row justify-content-center">
                  <Feature
                    className="col-lg-6 col-md-12 col-sm-12"
                    image={gov}
                    title={lang.yasser.landing.howOne.title}
                    body={lang.yasser.landing.howOne.body}
                  />
                  <Feature
                    className="col-lg-6 col-md-12 col-sm-12 first-feat"
                    image={calendar}
                    title={lang.yasser.landing.howTwo.title}
                    body={lang.yasser.landing.howTwo.body}
                  />
                </div>
                <div className="row justify-content-center">
                  <Feature
                    className="col-lg-6 col-md-12 col-sm-12"
                    image={qr}
                    title={lang.yasser.landing.howThree.title}
                    body={lang.yasser.landing.howThree.body}
                  />
                  <Feature
                    className="col-lg-6 col-md-12 col-sm-12"
                    image={queue}
                    title={lang.yasser.landing.howFour.title}
                    body={lang.yasser.landing.howFour.body}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-8 col-sm-6 how-iphone">
                <img src={iphone} alt="ios-simultor" className="mx-auto d-block iphone-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default How;
