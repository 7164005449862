import React, { useCallback, useEffect, useState } from 'react';
import { lang } from '../../lang';
import { SectionTitle } from '../common/sectionTitle';
import { InformationItem } from './information_item';

export const PersonalInformationSection = ({ email, mobile: initMobile, editMode, setInfo }) => {
  const [mobile, setMobile] = useState(initMobile);

  useEffect(() => {
    setMobile(initMobile);
  }, [initMobile]);

  useEffect(() => {
    mobile && mobile !== initMobile && setInfo({ mobile, email });
  }, [initMobile, mobile, email, setInfo]);

  const onMobileChange = useCallback(e => setMobile(e.target.value), []);

  return (
    <div>
      <SectionTitle className="mb-4">{lang.managerInformation.personalInformation}</SectionTitle>
      <InformationItem label={lang.label.email} value={email} className="mb-2" editMode={editMode} disabled />
      <InformationItem
        label={lang.label.mobile}
        value={mobile}
        className="mb-2"
        editMode={editMode}
        onChange={onMobileChange}
      />
    </div>
  );
};
