import React, { Fragment, useState, useContext } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { lang } from '../../lang';
import { Link } from 'react-router-dom';
import { request, APIs } from '../../request';
import Context from '../../context/Context';
import AlertError from '../../components/alert/error';
import Confirm from '../../components/alert/confirm';
import { safeReq } from '../../utils/request';
import { toast } from 'react-toastify';
import { IconEllipses } from '../../utils';

const TableActionButton = ({ store }) => {
  const { stores, setStores } = useContext(Context);
  const [showDeleteAlert, setDeleteAlert] = useState(false);
  const [showNoServiceAlert, setNoServiceAlert] = useState(false);

  const toggleStore = async () => {
    let storeId = store.id;
    safeReq(async () => {
      const {
        data: { data },
      } = await request.PUT(APIs.UPDATE_STORE, { storeId: storeId, active: !store.active });
      if (store.active !== data.active) {
        store.active ? toast.success(lang.stores.deactivateSuccess) : toast.success(lang.stores.activateSuccess);
        setStores(
          stores.map(st => {
            if (st.id === storeId) {
              st.active = !st.active;
            }
            return st;
          })
        );
      } else {
        setNoServiceAlert(true);
      }
    });
  };

  const deleteStore = async () => {
    let storeId = store.id;
    safeReq(async () => {
      await request.DELETE(APIs.STORES, { id: storeId });
      toast.success(lang.stores.deleteSuccess);
      setStores(stores.filter(e => e.id !== storeId));
      setDeleteAlert(false);
    });
  };

  return (
    <Fragment>
      <UncontrolledDropdown className="position-static">
        <DropdownToggle color="link" size="sm" className="text-600 btn-reveal">
          <IconEllipses className="fs--1" />
        </DropdownToggle>
        <DropdownMenu right className="border py-2">
          <Link to={`/stores/edit/${store.id}`}>
            <DropdownItem>{lang.tableActions.edit}</DropdownItem>
          </Link>
          <DropdownItem divider />
          {store.active ? (
            <DropdownItem className="text-warning" onClick={toggleStore}>
              {lang.tableActions.deactivate}
            </DropdownItem>
          ) : (
            <DropdownItem className="text-success" onClick={toggleStore}>
              {lang.tableActions.activate}
            </DropdownItem>
          )}
          <DropdownItem divider />
          <DropdownItem className="text-danger" onClick={() => setDeleteAlert(true)}>
            {lang.tableActions.delete}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <Confirm
        open={showDeleteAlert}
        confirm={deleteStore}
        cancel={() => setDeleteAlert(false)}
        title={lang.stores.confirmationDeleteStore}
        body={lang.stores.confirmationDeleteStoreMessage}
      />
      <AlertError
        open={showNoServiceAlert}
        onClick={() => setNoServiceAlert(false)}
        title={lang.sorry}
        body={lang.stores.storeWithoutServiceMsg}
      />
    </Fragment>
  );
};

export default TableActionButton;
