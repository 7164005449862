import axios from "axios";
import { AuthHelpers } from "../utils";
// import * as Sentry from "@sentry/browser";
// import { IS_PROD } from "../constants";
import APIs from "./apis";

const BASES_URLS = {
  BASE: process.env.REACT_APP_BASE_URL,
};

const methods = {
  POST: "post",
  GET: "get",
  PUT: "put",
  DELETE: "delete",
};

const prepareToken = () => AuthHelpers.getToken();

axios.interceptors.response.use(
  (response) => {
    const token = response.headers["x-auth-token"];
    if (token) {
      AuthHelpers.setToken(token);
    }
    return response;
  },
  (error) => {
    const err = error;
    const statusUnauthorized = 401;

    if (error && error.response && error.response.status === statusUnauthorized) {
      // signOut();
      // window.location = "/#/signin";
    }
    // if (IS_PROD) {
    //   Sentry.withScope(scope => {
    //     scope.setUser({ id: localStorage.getItem("username") || "anonymous" });
    //     scope.setLevel(Sentry.Severity.Debug);
    //     Sentry.captureException(err.response.data);
    //   });
    // }
    return Promise.reject(err);
  }
);

const templateEngine = (template, data) => {
  const re = /{([^}]*)?}/;
  let match = re.exec(template);
  let tpl = template;
  while (match) {
    if (match[0] && !data[match[1]]) {
      tpl = tpl.replace(`${match[1]}=`, "");
    }
    tpl = tpl.replace(match[0], data[match[1]] || "");
    match = re.exec(tpl);
  }
  return tpl;
};

const send = (method, url, d, customHeaders) => {
  if (!url) {
    throw new Error("URL PASSED TO THE REQUEST METHOD IS UNDEFINED. HINT: MAKE SURE THE SPELLING IS CORRECT");
  }
  let templateData = {};
  if (d instanceof FormData) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of d.keys()) {
      templateData[key] = d.get(key);
    }
  } else {
    templateData = d;
  }

  const data = d || {};
  const fullURL = templateEngine(url, { ...templateData, ...BASES_URLS });
  const token = prepareToken(fullURL);
  const headers = token
    ? {
        Accept: "application/json",
        "x-auth-token": token,
        authorization: `Bearer ${token}`,
        ...customHeaders,
      }
    : { Accept: "application/json", ...customHeaders };
  if (method === methods.POST || method === methods.PUT) {
    return axios[method](fullURL, data, { headers });
  }
  return axios[method](fullURL, { headers });
};

const sendPost = (url, data, headers) => send(methods.POST, url, data, headers);

const sendGet = (url, params) => send(methods.GET, url, params);

const sendPut = (url, data) => send(methods.PUT, url, data);

const sendDelete = (url, params) => send(methods.DELETE, url, params);

export const prepareURL = (url, d) => {
  const data = d || {};
  let fullURL = templateEngine(url, { ...data, ...BASES_URLS });
  const token = prepareToken(fullURL);
  fullURL = token ? `${fullURL}${url.indexOf("?") === -1 ? "?" : "&"}token=${token}` : token;
  return fullURL;
};

export const getErrors = (messages) => {
  const errors = [];
  if (messages) {
    Object.keys(messages).forEach((key) => {
      const catagory = messages[key];
      if (Array.isArray(catagory)) {
        catagory.forEach((msg) => {
          errors.push(msg);
        });
      } else {
        errors.push(catagory);
      }
    });
  }
  return errors;
};

export const request = {
  GET: sendGet,
  POST: sendPost,
  PUT: sendPut,
  DELETE: sendDelete,
};

export { APIs };
