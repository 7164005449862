import React, { Fragment } from "react";
import WizardInput from "../auth/wizard/WizardInput";
import { lang } from "../../lang";

const GuardInformations = ({ register, errors, data = {} }) => {
  return (
    <Fragment>
      <WizardInput
        label={`${lang.label.name}*`}
        name="name"
        id="name"
        errors={errors}
        defaultValue={data.name}
        innerRef={register({
          required: lang.requiredField
        })}
      />

      <WizardInput
        label={`${lang.label.mobile}*`}
        name="mobile"
        id="mobile"
        errors={errors}
        defaultValue={data.mobile}
        innerRef={register({
          required: lang.requiredField,
          pattern: {
            value: /^(05)[0-9]{8}$/,
            message: lang.validationMessage.mobileStart05
          }
        })}
      />
    </Fragment>
  );
};

export default GuardInformations;
