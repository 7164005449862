import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { isAR, lang } from '../../lang';
import AppContext from '../../context/Context';
import { Navbar, Nav, NavbarBrand, NavbarToggler, Collapse, NavItem } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/img/morajea-logo-white.png';

export default () => {
  const [active, setActive] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { toggleLang } = useContext(AppContext);
  const location = useLocation();
  const lng = lang.yasser.landing.header;

  useEffect(() => {
    if (window.location.hash === '#contact') {
      const yy = document.getElementById('contact').offsetTop;
      window.scrollTo(0, yy);
    }
  }, []);

  const smoothScroll = y => window.scrollTo({ top: y, behavior: 'smooth' });
  const onAppointmentsClick = () => {
    setActive('appointments');
    if (location.pathname === '/') {
      smoothScroll(document.getElementById('appointments').offsetTop);
    } else {
      smoothScroll(0);
    }
  };
  const onFeaturesClick = () => {
    setActive('how');
    if (location.pathname === '/') {
      smoothScroll(document.getElementById('how').offsetTop);
    } else {
      smoothScroll(0);
    }
  };

  const onContactClick = () => {
    setActive('download');
    if (location.pathname === '/') {
      smoothScroll(document.getElementById('download').offsetTop);
    } else {
      smoothScroll(0);
    }
  };

  window.addEventListener('scroll', e => {
    const appointments_offset =
      document.getElementById('appointments') && document.getElementById('appointments').offsetTop;
    const how_offset = document.getElementById('appointments') && document.getElementById('how').offsetTop;
    const download_offset = document.getElementById('appointments') && document.getElementById('download').offsetTop;
    const y = window.pageYOffset;
    if (y >= appointments_offset && y < how_offset && location.pathname === '/') {
      setActive('appointments');
    } else if (y >= how_offset && location.pathname === '/') {
      setActive('features');
    } else if (y >= download_offset && location.pathname === '/') {
      setActive('download');
    } else {
      setActive('');
    }
  });

  const toggle = () => setIsOpen(!isOpen);
  return (
    <header>
      <Navbar className="py-sm-2 py-xl-6 py-md-4 px-xl-6 px-md-6 px-4 header" expand="lg">
        <NavbarBrand className={`${isAR ? 'py-2' : 'py-2'} h-200`}>
          <Link to="/">
            <img height={120} style={{ borderRadius: '5px' }} src={logo} alt="logo" className="w-auto" />
          </Link>
          {/* <h4 className="text-white">{lang.yasser.landing.header.logo}</h4> */}
        </NavbarBrand>
        <NavbarToggler onClick={toggle}>
          <FontAwesomeIcon icon={faBars} />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mx-auto links px-0 px-md-4 px-xl-4" navbar>
            <NavItem>
              <Link to="/">
                <button className={`link ${active === 'home' ? 'active' : ''}`} onClick={onAppointmentsClick}>
                  {lng.features}
                </button>
              </Link>
            </NavItem>
            <NavItem>
              <Link to="/">
                <button className={`link ${active === 'appointments' ? 'active' : ''}`} onClick={onFeaturesClick}>
                  {lng.how}
                </button>
              </Link>
            </NavItem>
            <NavItem>
              <Link to="/">
                <button className={`link ${active === 'features' ? 'active' : ''}`} onClick={onContactClick}>
                  {lng.download}
                </button>
              </Link>
            </NavItem>
            <NavItem>
            <Link to="/auth/login" >
              <button className="link">
              {isAR ? 'تسجيل الدخول' : 'Login'}
              </button>
            </Link>
            </NavItem>
          </Nav>
          <div className="px-2 py-2 d-flex flex-nowrap header-util">
            <button
              style={{
                border: 'none',
                backgroundColor: 'transparent',
                color: 'white',
                margin: '0 10px',
                fontWeight: 'bold',
              }}
              onClick={toggleLang}
            >
              {isAR ? 'EN' : 'ع'}
            </button>
          </div>
        </Collapse>
      </Navbar>
    </header>
  );
};
