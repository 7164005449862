import React, { useContext, useCallback } from 'react';
import { Nav } from 'reactstrap';
import StoresDropDown from './StoresDropdown';
import { request, APIs } from '../../request';
import AppContext from '../../context/Context';
import { ROLES } from '../../constants';
import { lang } from '../../lang';
import useSWR from 'swr';

const TopNavLeftSideNavItem = () => {
  const { setStores, stores, setSelectedStore, user, currentPage } = useContext(AppContext);

  const fetchStores = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await request.GET(APIs.STORES);
      setStores(data);
      if (data.length && user.role === ROLES.MANAGER) {
        setSelectedStore(data[0]);
      }
      return data;
    } catch (err) {
      console.log(err);
    }
  }, [user, setSelectedStore, setStores]);

  const { data = [] } = useSWR(APIs.STORES, fetchStores);
  const res = data || stores;

  return (
    <Nav navbar className="navbar-nav-icons flex-row align-items-center pt-3">
      <h2 id="header_title" className="font-weight-bold mr-2">
        {lang.navMenu[currentPage]}
      </h2>
      <StoresDropDown stores={res} />
    </Nav>
  );
};

export default TopNavLeftSideNavItem;
