export class AuthHelpers {
  static setToken(token) {
    localStorage.setItem('announcements-token', token);
  }

  static isAuthenticated() {
    if (localStorage.getItem('announcements-token') === null) {
      return false;
    } else {
      return true;
    }
  }

  static getToken() {
    return localStorage.getItem('announcements-token');
  }

  static removeToken() {
    localStorage.removeItem('announcements-token');
  }
  static logout() {
    AuthHelpers.removeToken();
    localStorage.removeItem('user');
    localStorage.removeItem('selectedStore');
    localStorage.removeItem('brand');
  }
}
