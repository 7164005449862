import React, { useState, useContext, useEffect, useCallback } from "react";
import { CardBody, Card } from "reactstrap";
import classNames from "classnames";
import { Shift } from "./Shift";
import Loader from "../common/Loader";
import AppContext from "../../context/Context";
import { request, APIs } from "../../request";
import CardTabs from "../common/CardTabs";

export default () => {
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [shifts, setShifts] = useState([]);
  const { selectedStore } = useContext(AppContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await request.GET(APIs.STORE_SHIFTS, { id: selectedStore.id });
        const shifts = data.data;
        setShifts(shifts);
        setLoading(false);
      } catch (e) {
        addShift();
        setLoading(false);
        console.log(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !shifts.length && addShift();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shifts]);

  const addShift = () => {
    setShifts([...shifts, { shift_name: `Shift ${shifts.length + 1}` }]);
    setTab(shifts.length);
  };

  const deleteShift = (index) => {
    setShifts(shifts.filter((_, i) => i !== index));
    setTab(index - 1);
  };

  const updateShift = (index, new_shift) =>
    setShifts(shifts.map((shift, i) => (i === index ? { ...new_shift } : shift)));

  const prepareShiftsData = useCallback(() => {
    return shifts.map((shift) => ({ name: shift.shift_name, id: shift.id, hideCloseButton: !!shift.id }));
  }, [shifts]);

  const onTabChange = useCallback((index) => {
    setTab(index);
  }, []);

  return (
    <div className="schedule">
      <CardTabs
        tabs={prepareShiftsData()}
        closeTab={deleteShift}
        addTab={addShift}
        onTabChange={onTabChange}
        selectedTab={tab}
      />
      <Card>
        <CardBody>
          <div className="tab-content" id="myTabContent">
            {loading ? (
              <Loader />
            ) : (
              shifts.map((shift, index) => (
                <Shift
                  key={shift.name + index}
                  data={shift}
                  shifts={shifts}
                  shiftsNumber={shifts.length}
                  deleteShift={() => deleteShift(index)}
                  updateShift={(shift) => updateShift(index, shift)}
                  className={classNames("tab-pane fade", { show: tab === index, active: tab === index })}
                />
              ))
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
