import React, { useContext, useEffect } from 'react';
import { useState, useCallback } from 'react';
import { Filters } from '../../components/branches-activity/Filters';
import { Search } from '../../components/branches-activity/Search';
import { ActivityTable } from '../../components/branches-activity/ActivityTable';
import AppContext from '../../context/Context';
import { APIs, request } from '../../request';
import { toast } from 'react-toastify';
import { REVALIDATE_TIME } from '../../constants';
import useSWR from 'swr';
import { lang } from '../../lang';
import {
  getDayAppts,
  getMonthAppts,
  getPreviousDayAppts,
  getPreviousMonthAppts,
  getPreviousWeekAppts,
  getWeekAppts,
  prepareBranchActivity,
  search,
} from '../../utils';
import { useLocation } from 'react-router';

const BranchesActivity = () => {
  let loading = true;

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const { stores: raw_stores } = useContext(AppContext);
  const [filter, setFilter] = useState(urlParams.get('duration') || Filters.VALUES.DAY);
  const [appts, setAppts] = useState([]);
  const [prevAppts, setPrevAppts] = useState([]);
  const [term, setTerm] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchAppts = useCallback(async URL => {
    try {
      const {
        data: { data: appts },
      } = await request.GET(URL, { per_page: 500 });
      return appts;
    } catch (e) {
      toast.error(lang.defaultError);
      console.log({ e });
    }
  }, []);

  const { data: raw_appts } = useSWR(APIs.BRAND_APPTS, fetchAppts, {
    refreshInterval: REVALIDATE_TIME,
  });
  loading = !raw_appts;

  useEffect(() => {
    let res = [];
    let prevRes = [];
    if (filter === Filters.VALUES.MONTH) {
      res = getMonthAppts(raw_appts || []);
      prevRes = getPreviousMonthAppts(raw_appts || []);
    } else if (filter === Filters.VALUES.WEEK) {
      res = getWeekAppts(raw_appts || []);
      prevRes = getPreviousWeekAppts(raw_appts || []);
    } else {
      res = getDayAppts(raw_appts || []);
      prevRes = getPreviousDayAppts(raw_appts || []);
    }
    setAppts(res);
    setPrevAppts(prevRes);
  }, [filter, raw_appts]);

  let stores = prepareBranchActivity(appts, prevAppts, search(raw_stores, ['name_en', 'name'], term));
  stores = stores?.sort((a, b) => {
    return b.totalAppts - a.totalAppts;
  });
  return (
    <div className="space-m px-2 branches-activity">
      <div>
        <h3 className="dashboard-page-title">{lang.dashboardItems.branchesActivity}</h3>
      </div>
      <div className="px-2 py-3 d-flex justify-content-between">
        <Search value={term} onChange={setTerm} />
        <Filters value={filter} onChange={setFilter} />
      </div>
      <ActivityTable data={stores} loading={loading} />
    </div>
  );
};

export default BranchesActivity;
