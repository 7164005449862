import React, { useState, Fragment, useContext, useEffect, useCallback } from 'react';
import { Card, CardBody, CardFooter, CardHeader, Form, Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
// import Alert from '../../components/alert/success';
import Loader from '../../components/common/Loader';
import GuardInformation from '../../components/guards/GuardInformation';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { lang } from '../../lang';
import { request, APIs } from '../../request';
import AppContext from '../../context/Context';
import { safeReq } from '../../utils/request';
import { toast } from 'react-toastify';

const EditModerator = () => {
  // const [showAlert, setShowAlert] = useState(false);
  const history = useHistory();
  const { params } = useRouteMatch();
  const { handleSubmit, errors, register } = useForm();
  const [loading, setLoading] = useState(true);
  const [targeted_guard, setGuard] = useState([]);
  const { selectedStore } = useContext(AppContext);
  const guard_id = params.id;

  const fetchData = useCallback(async () => {
    const { data } = await request.GET(APIs.STORE_STAFF, { store_id: selectedStore.id });

    const guard = data.data.find(guard => `${guard.id}` === `${guard_id}`) || {};
    setGuard(guard);
    setLoading(false);
  }, [selectedStore.id, guard_id]);

  useEffect(() => {
    fetchData(guard_id);
  }, [fetchData, guard_id]);

  const onSubmitData = data => {
    safeReq(async () => {
      try {
        const body = { id: params.id, ...data };
        await request.PUT(APIs.STAFF, body);
        toast.success(lang.moderators.editModerator.editSuccess);
        // setShowAlert(true);
        setTimeout(() => {
          history.push('/guards');
        }, 500);
      } catch (err) {
        console.log(err);
        toast.error(lang.defaultError);
      }
    });
  };

  return (
    <Fragment>
      <Card tag={Form} onSubmit={handleSubmit(onSubmitData)} className="theme-wizard">
        <CardHeader className="bg-light">
          <span className="d-none d-md-block mt-1 fs--1">{lang.guards.editGuard.title}</span>
        </CardHeader>

        {loading ? (
          <Loader />
        ) : (
          <CardBody className="fs--1 font-weight-normal px-md-6 pt-4 pb-3">
            <GuardInformation errors={errors} data={targeted_guard} register={register} />
          </CardBody>
        )}
        <CardFooter className="px-md-6 bg-light d-flex">
          <Button color="primary" className="ml-auto px-5" type="submit" transform="down-1 shrink-4">
            {lang.btn.save}
          </Button>
        </CardFooter>
      </Card>
      {/* <Alert open={showAlert} onClick={() => history.push('/guards')} title={lang.guards.editGuard.editSuccess} /> */}
    </Fragment>
  );
};

export default EditModerator;
