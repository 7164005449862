import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Jumbotron,
  ListGroup,
  ListGroupItem,
  Row,
  Alert,
} from 'reactstrap';
import Container from 'reactstrap/es/Container';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isAR, lang } from '../../lang';
import NotificationRow from '../../components/notification/NotificationRow';
import { APIs, request } from '../../request';
import { toast } from 'react-toastify';
import useSWR from 'swr';
import { date } from '../../utils';
import AppContext from '../../context/Context';
import { PAGE_TITLE_KEY } from '../../routes';

const NotificationPage = () => {
  const { setCurrentPage } = useContext(AppContext);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [todayNotifications, setTodayNotifications] = useState([]);
  const [weekNotifications, setWeekNotifications] = useState([]);
  const [monthNotifications, setMonthNotifications] = useState([]);

  const fetchNotifications = useCallback(async URL => {
    try {
      const {
        data: { data },
      } = await request.GET(URL);
      setFilteredNotifications(data);
      await markAsRead(URL, data.map(ele => ele.id));
      return data;
    } catch (e) {
      toast.error(lang.defaultError);
      console.log({ e });
    }
  }, []);

  const { data: notifications = [], mutate } = useSWR(APIs.NOTIFICATIONS, fetchNotifications, {
    refreshInterval: 60000,
  });

  const markAsRead = async (URL, ids) => {
    try {
      const {
        data: { data },
      } = await request.PUT(URL, { ids: ids });
      return data;
    } catch (e) {
      toast.error(lang.defaultError);
      console.log({ e });
    }
  };

  useEffect(() => {
    setCurrentPage(PAGE_TITLE_KEY.NOTIFICATIONS);
    setTodayNotifications(
      filteredNotifications.filter(ele => new Date(ele.created_at).getDate() === new Date().getDate())
    );
    setWeekNotifications(
      filteredNotifications.filter(ele => {
        const diff = date.diff(new Date().getDate(), new Date(ele.created_at).getDate());
        return diff >= 1 && diff <= 7;
      })
    );
    setMonthNotifications(
      filteredNotifications.filter(ele => date.diff(new Date().getDate(), new Date(ele.created_at).getDate()) > 7)
    );
  }, [setCurrentPage, filteredNotifications]);

  const selectAll = () => {
    selectedRows.length < notifications.length ? setSelectedRows(notifications.map(a => a.id)) : setSelectedRows([]);
  };

  const handleRowSelection = id => {
    selectedRows.includes(id)
      ? setSelectedRows(selectedRows.filter(ele => ele !== id))
      : setSelectedRows([...selectedRows, id]);
  };

  // handle search
  function handleChange(e) {
    const text = e.target.value
      .toLowerCase()
      .replace(/أ|إ/g, 'ا')
      .replace(/ة/g, 'ه');
    setFilteredNotifications(
      text.length
        ? notifications.filter(ele =>
            (
              lang.notifications[ele.data.type]
                .toLowerCase()
                .replace(/أ|إ/g, 'ا')
                .replace(/ة/g, 'ه') +
              ele.data.payload.causer_name
                ?.toLowerCase()
                .replace(/أ|إ/g, 'ا')
                .replace(/ة/g, 'ه') +
              ele.data.payload.name_ar?.replace(/أ|إ/g, 'ا').replace(/ة/g, 'ه') +
              ele.data.payload.name_en?.toLowerCase()
            ).includes(text)
          )
        : notifications
    );
  }

  const deleteNotifications = useCallback(async () => {
    try {
      console.log(selectedRows);
      await request.PUT(APIs.CLEAR_NOTIFICATIONS, {
        ids: selectedRows,
      });
      mutate();
      setSelectedRows([]);
      toast.success(lang.notifications.deleted_successfully);
    } catch (err) {
      toast.error(lang.defaultError);
    }
  }, [selectedRows, mutate]);

  return (
    <Fragment>
      {/*topBar*/}
      <Row className="justify-content-between mx-5 my-2">
        {/*search*/}
        <Col md={6} lg={4} xl={3} className="mb-3">
          <InputGroup>
            <Input
              placeholder={lang.search}
              type={'text'}
              className="search-notification-box px-4"
              onChange={handleChange}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText className="search-notification-box-icon bg-white search-notification-box">
                <FontAwesomeIcon icon={faSearch} size="1x" className="mx-2" />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>

        {/*delete and select all btn*/}
        {!!filteredNotifications.length ? (
          <Col md={3} lg={2} className="row justify-content-center">
            <Button
              className="delete-btn"
              size="sm"
              block
              style={{ visibility: selectedRows.length ? 'visible' : 'hidden' }}
              disabled={!selectedRows.length}
              onClick={deleteNotifications}
            >
              {lang.delete}
            </Button>

            <Button className="select-all-btn" size="sm" block onClick={selectAll}>
              {selectedRows.length < notifications.length ? lang.selectAll : lang.deselectAll}
            </Button>
          </Col>
        ) : null}
      </Row>

      {!filteredNotifications.length ? (
        <Alert color="info" className="mb-0">
          {lang.notifications.no_notifications}
        </Alert>
      ) : null}

      {/*notifications*/}
      <div className="mx-5 my-1">
        {/*today*/}
        {!!todayNotifications.length ? (
          <Jumbotron fluid className="py-0">
            <Container fluid>
              <div className="group-header my-3">{lang.dates.today}</div>
              <Card>
                <CardBody>
                  <ListGroup flush>
                    {todayNotifications.map(n => (
                      <ListGroupItem key={n.id}>
                        <NotificationRow
                          selected={selectedRows.includes(n.id)}
                          handleRowSelection={handleRowSelection}
                          notification={n}
                          range="time"
                        />
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </CardBody>
              </Card>
            </Container>
          </Jumbotron>
        ) : null}

        {/*last week*/}
        {!!weekNotifications.length ? (
          <Jumbotron fluid className="py-0">
            <Container fluid>
              <div className="group-header mb-3">{lang.dates.lastWeek}</div>
              <Card>
                <CardBody>
                  <ListGroup flush>
                    {weekNotifications.map(n => (
                      <ListGroupItem key={n.id}>
                        <NotificationRow
                          selected={selectedRows.includes(n.id)}
                          handleRowSelection={handleRowSelection}
                          notification={n}
                          range="day"
                        />
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </CardBody>
              </Card>
            </Container>
          </Jumbotron>
        ) : null}

        {/*before last week*/}
        {!!monthNotifications.length ? (
          <Jumbotron fluid className="py-0">
            <Container fluid>
              <div className="group-header mb-3">
                {Intl.DateTimeFormat(isAR ? 'ar-AR' : 'en-En', {
                  month: 'long',
                  calendar: isAR ? 'islamic' : 'gregory',
                }).format(new Date().setDate(new Date().getDate() - 7))}{' '}
                {lang.dates.andEarlier}
              </div>
              <Card>
                <CardBody>
                  <ListGroup flush>
                    {monthNotifications.map(n => (
                      <ListGroupItem key={n.id}>
                        <NotificationRow
                          selected={selectedRows.includes(n.id)}
                          handleRowSelection={handleRowSelection}
                          notification={n}
                          range="date"
                        />
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </CardBody>
              </Card>
            </Container>
          </Jumbotron>
        ) : null}
      </div>
    </Fragment>
  );
};

export default NotificationPage;
