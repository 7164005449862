import { useEffect } from 'react';
import { useState } from 'react';

export * from './auth';
export * from './date';
export * from './icons';
export * from './helpers';
export * from './d3';
export * from './appt';

export const groupBy = (array, property) => {
  const keys = property.split('.');
  return array?.reduce((r, a) => {
    try {
      const key = keys.reduce((accumulator, currentValue) => accumulator[currentValue], a);
      r[key] = [...(r[key] || []), a];
    } catch (e) {
      console.log(e);
    }
    return r;
  }, {});
};

export const prepareDate = d => {
  const date = new Date(d);
  const month = `${date.getMonth() + 1}`.padStart(2, 0);
  const day = `${date.getDate()}`.padStart(2, 0);
  return `${day}/${month}/${date.getFullYear()}`;
};

export const prepareTime = d => {
  const date = new Date(d);
  return `${(date.getHours() + '').padStart(2, '0')}:${(date.getMinutes() + '').padStart(2, '0')}`;
};

export const prepareShiftTimeLabel = time => {
  const hours = time.split(':')[0];
  const min = time.split(':')[1];
  return hours >= '12' ? `${hours > 12 ? hours % 12 : 12}:${min} PM` : `${hours}:${min} AM`;
};

export const range = (start, end, step = 1) => {
  const arr = [];
  for (let i = end ? start : 0; i < (end || start); i += step) {
    arr.push(i);
  }
  return arr;
};

export const search = (source, branch, keyword, limit = 100) => {
  if (!keyword) {
    return source;
  }
  if (!limit) {
    limit = 9999;
  }
  if (!Array.isArray(branch)) {
    branch = [branch];
  }
  let result = [];
  keyword = keyword
    .toLowerCase()
    .replace(/أ|إ/g, 'ا')
    .replace(/ة/g, 'ه');
  for (let i = 0; i < source.length; i++) {
    for (let j = 0; j < branch.length; j++) {
      if (
        source[i][branch[j]] &&
        source[i][branch[j]]
          .toLowerCase()
          .replace(/أ|إ/g, 'ا')
          .replace(/ة/g, 'ه')
          .includes(keyword)
      ) {
        result.push(source[i]);
        break;
      }
    }
  }
  return result;
};

const RATIOS = {
  HD: 1.7,
  FULL_HD: 2.15,
  WIDE_HD: 2.4,
};

export const useWindowRatio = (ratio = []) => {
  const [info, setInfo] = useState({});
  let current_ratio = 0;

  const checkScreenSize = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    // start with poor (low) aspect ratio
    const ratio = width / height;
    if (ratio <= RATIOS.HD) {
      if (current_ratio !== '16:10') {
        current_ratio = '16:10';
        setInfo({ width, height, ratio: '16:10' });
      }
      return;
    } else if (ratio <= RATIOS.FULL_HD) {
      if (current_ratio !== '16:9') {
        current_ratio = '16:9';
        setInfo({ width, height, ratio: '16:9' });
      }
      return;
    } else {
      current_ratio = '21:9';
      setInfo({ width, height, ratio: '21:9' });
      return;
    }
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return info;
};
