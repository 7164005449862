import React, { useState, useEffect } from 'react';
import { StatusCard } from '../appointments/StatusCard';
import { faCheckCircle, faTimesCircle, faClock, faBan, faCalendar, faWalking } from '@fortawesome/free-solid-svg-icons';
import { lang } from '../../lang';
import { APPOINTMENT_STATUS } from '../../constants';
import { date, groupBy } from '../../utils';

const statuses = [
  {
    status: '',
    subTitle: 'perDay',
    value: '',
    icon: faCalendar,
    color: '#005a96',
  },
  {
    status: APPOINTMENT_STATUS.PENDING,
    subTitle: 'appointment',
    value: '',
    icon: faClock,
    color: '#329dec',
  },
  {
    subTitle: 'appointment',
    status: APPOINTMENT_STATUS.CANCELED,
    value: '',
    icon: faTimesCircle,
    color: '#cdccd4',
  },
  {
    subTitle: 'appointment',
    status: APPOINTMENT_STATUS.REJECTED,
    value: '',
    icon: faBan,
    color: '#444061',
  },
  {
    subTitle: 'appointment',
    status: APPOINTMENT_STATUS.ACCEPTED,
    value: '',
    icon: faCheckCircle,
    color: '#28AA8C',
  },
  {
    subTitle: 'appointment',
    status: APPOINTMENT_STATUS.WALK_IN,
    value: '',
    icon: faWalking,
    color: '#4fcdba',
  },
];

export const AppointmentsStatuses = ({ data, className, loading }) => {
  const [total, setTotal] = useState(0);
  const [pending, setPending] = useState(0);
  const [canceled, setCanceled] = useState(0);
  const [accepted, setAccepted] = useState(0);
  const [rejected, setRejected] = useState(0);
  const [walkIn, setWalkIn] = useState(0);

  const renderValue = status => {
    switch (status) {
      case APPOINTMENT_STATUS.PENDING:
        return pending;
      case APPOINTMENT_STATUS.ACCEPTED:
        return accepted;
      case APPOINTMENT_STATUS.CANCELED:
        return canceled;
      case APPOINTMENT_STATUS.REJECTED:
        return rejected;
      case APPOINTMENT_STATUS.WALK_IN:
        return walkIn;
      default:
        return total;
    }
  };

  useEffect(() => {
    const appts = data.filter(ap => date.isToday(new Date(ap.start_datetime)));
    setTotal(appts.length);
    if (appts.length) {
      const filtered = groupBy(appts, 'status');
      filtered[APPOINTMENT_STATUS.PENDING] &&
        setPending(
          filtered[APPOINTMENT_STATUS.PENDING].filter(appt => !date.isBefore(new Date(appt.end_datetime), new Date()))
            .length
        );
      const accepted = filtered[APPOINTMENT_STATUS.ACCEPTED] || [];
      const confirmed = filtered[APPOINTMENT_STATUS.CONFIRMED] || [];
      const joined = accepted.concat(confirmed);
      setAccepted(joined.filter(appt => !date.isBefore(new Date(appt.end_datetime), new Date())).length);
      filtered[APPOINTMENT_STATUS.WALK_IN] && setWalkIn(filtered[APPOINTMENT_STATUS.WALK_IN].length);
      filtered[APPOINTMENT_STATUS.CANCELED] && setCanceled(filtered[APPOINTMENT_STATUS.CANCELED].length);
      filtered[APPOINTMENT_STATUS.REJECTED] && setRejected(filtered[APPOINTMENT_STATUS.REJECTED].length);
    }
  }, [data]);
  return (
    <div className={className} style={{ margin: '0 -13px' }}>
      <div className="d-flex h-100 justify-content-between w-100 flex-wrap">
        {statuses.map(status => (
          <StatusCard
            title={lang.appointments[status.status] || lang.dashboardItems.appointments}
            subTitle={lang.dashboardItems[status.subTitle]}
            value={renderValue(status.status)}
            color={status.color}
            icon={status.icon}
            iconSize="27px"
            className="space-hlf-mx h-100"
            loading={loading}
          />
        ))}
      </div>
    </div>
  );
};
