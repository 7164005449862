import DASHBOARD from './dashboard';

export default {
  API_TEST: '{BASE}/users',
  CONTACT: '{BASE}/contact',
  LOGIN: '{BASE}/owners/login',
  NOTIFICATIONS: '{BASE}/owners/notifications',
  CLEAR_NOTIFICATIONS: '{BASE}/owners/notifications/clear',
  BRAND: '{BASE}/owners/brand/{id}',
  STORES: '{BASE}/owners/stores/{id}?role[]=owner&role[]=manager',
  CREATE_STORE: '{BASE}/owners/stores',
  WORKING_PLAN: '{BASE}/owners/brand/{brandId}/stores/{storeId}/working_plan',
  APPOINTMENTS:
    '{BASE}/owners/stores/{store_id}/appointments?status={status}&from={from}&to={to}&page={page}&sortBy={sortBy}&sortType={sortType}&name={name}&service_id={service_id}&mobile={mobile}&fromTime={fromTime}&toTime={toTime}&per_page={per_page}',
  BRAND_APPTS: '{BASE}/owners/appointments?status={status}&from={from}&to={to}&per_page={per_page}',
  ACCEPT_APPOINTMENT: '{BASE}/owners/appointments/{appointmentId}/accept',
  REJECT_APPOINTMENT: '{BASE}/owners/appointments/{appointmentId}/reject',
  CANCEL_APPOINTMENTS: '{BASE}/owners/appointments/{appointmentId}',
  ACCEPT_ALL_APPOINTMENTS: '{BASE}/owners/appointments/accept',
  STAFF: '{BASE}/owners/staffs/{id}',
  STORE_STAFF: '{BASE}/owners/stores/{store_id}/staffs',
  UPDATE_STORE: '{BASE}/owners/stores/{storeId}',
  UPLOAD_LOGO: '{BASE}/owners/brand/{brandId}/upload_logo',
  UPDATE_BRAND: '{BASE}/owners/brand/{brandId}',
  RESET_PASSWORD: '{BASE}/owners/reset_password',
  FORGET_PASSWORD: '{BASE}/owners/forget_password',
  STORE_SHIFTS: '{BASE}/owners/stores/{id}/shifts',
  SHIFT: '{BASE}/owners/shifts/{id}',
  SERVICES: '{BASE}/owners/services/{service_id}',
  STORE_SERVICES: '{BASE}/owners/stores/{store_id}/services?trashed={trashed}',
  PACKAGES: '{BASE}/owners/packages/{id}',
  INVITATIONS: '{BASE}/owners/stores/{id}/invitations',
  SERVICE_INVITATIONS: '{BASE}/owners/invitations',
  INVITATION: '{BASE}/owners/invitations/{id}',
  USER_MANUALS: '{BASE}/files/manuals/{filename}?attachment={attachment}',
  STORE_CONFIG: '{BASE}/owners/stores/{store_id}/config',
  DASHBOARD,
};
