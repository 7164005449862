import React, { Fragment, useEffect, useContext, useState, useCallback } from 'react';
import { Card, CardBody, Col, Row, Form, FormGroup, Label, Container, Media, Button } from 'reactstrap';
import FalconCardHeader from '../../components/common/FalconCardHeader';
import { lang } from '../../lang';
import { request, APIs } from '../../request';
import AppContext from '../../context/Context';
import Loader from '../../components/common/Loader';
import Avatar from '../../components/common/Avatar';
import { isIterableArray } from '../../utils';
import { useHistory } from 'react-router-dom';
import Alert from '../../components/alert/success';
import FalconDropzone from '../../components/common/FalconDropzone';

const BrandInformation = () => {
  const { setCurrentPage } = useContext(AppContext);
  const history = useHistory();
  const { brand, setBrand } = useContext(AppContext);
  let [brandInfo, setInfo] = useState({});
  let [loading, setLoading] = useState(true);
  let [uploading, setUploading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [avatar, setAvatar] = useState([{ src: brand.logo ? brand.logo.file_url : '' }]);

  const fetchInfo = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await request.GET(APIs.BRAND, { id: brand.id });
      setInfo(data);
      setBrand(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, [brand.id, setBrand]);

  useEffect(() => {
    setCurrentPage('brandSettings');
    fetchInfo();
  }, [setCurrentPage, fetchInfo]);

  const onDrop = files => {
    setAvatar(files);
    // console.log(files, brandInfo)

    let updateLogo = document.querySelector('#update-logo');
    updateLogo.removeAttribute('disabled');
    updateLogo.classList.remove('disabled');
    updateLogo.addEventListener('click', function() {
      uploadBrandLogo(files);
    });
  };

  const uploadBrandLogo = async files => {
    try {
      const form = new FormData();
      form.append('logo', files[0].file);
      form.append('brandId', brandInfo.id);
      setUploading(true);
      await request.POST(APIs.UPLOAD_LOGO, form);
      setUploading(false);
      fetchInfo();
      // updateBrandInfo();
      setShowAlert(true);
    } catch (err) {
      console.log(err);
    }
  };

  // const updateBrandInfo = async (logoID) => {
  //   // setShowAlert(true);
  //   // let storeId = storeInfo.id;
  //   // console.log(brandInfo)
  //   try {
  //     const body = {
  //       name: brandInfo.name,
  //       name_en: brandInfo.name_en,
  //       description: brandInfo.description,
  //       description_en: brandInfo.description_en,
  //       brandId: brandInfo.id,
  //     };
  //     await request.POST(APIs.UPDATE_BRAND, body);
  //     setShowAlert(true);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return (
    <Fragment>
      <Container>
        <Card className="mb-3">
          <FalconCardHeader title={lang.brandInformation.navTitle}>
            <Button color="primary" className="mr-2" disabled id="update-logo">
              {lang.btn.update}
            </Button>
          </FalconCardHeader>
          {loading ? (
            <Loader />
          ) : (
            <CardBody>
              <Form>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="en-name">{lang.brandInformation.enName}</Label>
                      <h5 className="text-primary">{brandInfo.name_en}</h5>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="ar-name">{lang.brandInformation.arName}</Label>
                      <h5 className="text-primary">{brandInfo.name}</h5>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="en-description">{lang.brandInformation.enDesc}</Label>
                      <h5 className="text-primary">{brandInfo.description_en}</h5>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="ar-description">{lang.brandInformation.arDesc}</Label>
                      <h5 className="text-primary">{brandInfo.description}</h5>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <h6 style={{ color: '#676c6e' }}>{lang.brandInformation.logoUpload}</h6>
                    <FormGroup className="d-flex pt-2">
                      <div className="position-relative">
                        <Avatar
                          size="4xl"
                          className="mb-2"
                          rounded={false}
                          src={isIterableArray(avatar) ? avatar[0].base64 || avatar[0].src : ''}
                        />
                        {uploading ? (
                          <>
                            <div style={{ top: 0, opacity: 0.8 }} className="bg-white position-absolute h-100 w-100" />
                            <Loader
                              type="border"
                              className="position-absolute m-auto"
                              style={{ left: 0, right: 0, top: 0, bottom: 15 }}
                            />
                          </>
                        ) : null}
                      </div>

                      <Media body className="ml-md-4">
                        <FalconDropzone
                          files={avatar}
                          onChange={onDrop}
                          multiple={false}
                          accept="image/*"
                          placeholder={
                            <Fragment>
                              <Media className=" fs-0 mx-auto d-inline-flex align-items-center">
                                <Media>
                                  <p className="fs-0 mb-0 text-primary">
                                    {lang.brandInformation.chooseFile}{' '}
                                    <span className="text-700"> {lang.brandInformation.dragFile}</span>
                                  </p>
                                </Media>
                              </Media>

                              <p className="small mb-1 w-75 mx-auto text-500 pt-1">
                                {lang.brandInformation.uploadSize}
                              </p>
                            </Fragment>
                          }
                        />
                      </Media>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          )}
        </Card>
      </Container>
      <Alert
        open={showAlert}
        onClick={() => history.push('/dashboard')}
        title={lang.brandInformation.editLogoSuccess}
      />
    </Fragment>
  );
};

export default BrandInformation;
