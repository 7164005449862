import React, { useContext, Fragment } from "react";
import { Card, CardBody, CardFooter, Form } from "reactstrap";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import AppContext from "../../context/Context";
import ButtonIcon from "../../components/common/ButtonIcon";
import ModeratorInfomation from "../../components/moderators/ModeratorInformation";
import FalconCardHeader from "../../components/common/FalconCardHeader";
import { useHistory } from "react-router-dom";
import { lang } from "../../lang";
import { request, APIs } from "../../request";
import { ROLES } from "../../constants";
import { toast } from "react-toastify";

const AddModerator = () => {
  const history = useHistory();
  const { selectedStore } = useContext(AppContext);
  const { handleSubmit, errors, watch, register } = useForm();

  const onSubmitData = async (form) => {
    try {
      const body = { ...form, manageable_id: selectedStore.id, manageable_type: "store", role: ROLES.MANAGER };
      await request.POST(APIs.STAFF, body);
      toast.success(lang.moderators.addModerator.success);
      history.push("/moderators");
    } catch (err) {
      console.log(err);
      toast.error(lang.defaultError);
    }
  };

  return (
    <Fragment>
      <FalconCardHeader title={lang.moderators.addModerator.title} />
      <Card tag={Form} onSubmit={handleSubmit(onSubmitData)} className="theme-wizard">
        <CardBody className="fs--1 font-weight-normal px-md-6 pt-4 pb-3">
          <ModeratorInfomation errors={errors} watch={watch} register={register} />
        </CardBody>
        <CardFooter className={classNames("px-md-6 bg-light d-flex")}>
          <ButtonIcon color="primary" className="ml-auto px-5" type="submit" transform="down-1 shrink-4">
            {lang.btn.save}
          </ButtonIcon>
        </CardFooter>
      </Card>
    </Fragment>
  );
};

export default AddModerator;
