import React, { Fragment, createRef } from "react";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Col, Row } from "reactstrap";
import ButtonIcon from "../common/ButtonIcon";
import { lang, isAR } from "../../lang";
// import TableActionsMenu from "./TableActionButton";
import { INVITATION_STATUS } from "../../constants";
import Badge from "reactstrap/es/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { date } from "../../utils/date";

const InvitationsTable = ({ invitations, cancelInv }) => {
  const prepareData = () =>
    invitations
      .map((e) => ({
        ...e,
        pre_msg: e.pre_msg || "-",
        service_name: e.service[isAR ? "name_ar" : "name_en"],
        date: date.format(new Date(e.datetime), "dd/MM/yyyy"),
        time: date.format(new Date(e.datetime), "hh:mm aa"),
        action:
          e.status === INVITATION_STATUS.PENDING ? (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a className="cancel-invitation" href="#" onClick={() => cancelInv(e.id)}>
              {lang.cancel}
            </a>
          ) : (
            ""
          ),
        // action: <TableActionsMenu />
      }))
      .sort((a, b) => b.id - a.id);

  const options = {
    custom: true,
    sizePerPage: 6,
    totalSize: invitations.length,
  };

  return (
    <PaginationProvider pagination={paginationFactory(options)}>
      {({ paginationProps, paginationTableProps }) => {
        const lastIndex = paginationProps.page * paginationProps.sizePerPage;

        return (
          <Fragment>
            <div className="table-responsive">
              <BootstrapTable
                ref={table}
                bootstrap4
                keyField="id"
                data={prepareData()}
                columns={prepareColumns()}
                bordered={false}
                classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                rowClasses="btn-reveal-trigger border-top border-200"
                headerClasses="bg-200 text-900 border-y border-200"
                {...paginationTableProps}
              />
            </div>
            <Row noGutters className="px-1 py-3">
              <Col className="pl-3 fs--1">
                <CustomTotal {...paginationProps} lastIndex={lastIndex} />
                <ButtonIcon
                  color="link"
                  size="sm"
                  icon={`chevron-${isAR ? "left" : "right"}`}
                  iconAlign="right"
                  transform="down-1 shrink-4"
                  className="px-0 font-weight-semi-bold"
                  onClick={() => handleViewAll(paginationProps, invitations.length)}
                >
                  {lang.btn.viewAll}
                </ButtonIcon>
              </Col>
              <Col xs="auto" className="pr-3">
                <Button
                  color={paginationProps.page === 1 ? "light" : "primary"}
                  size="sm"
                  onClick={handlePrevPage(paginationProps)}
                  disabled={paginationProps.page === 1}
                  className="px-4"
                >
                  {lang.btn.previous}
                </Button>
                <Button
                  color={lastIndex >= paginationProps.totalSize ? "light" : "primary"}
                  size="sm"
                  onClick={handleNextPage(paginationProps)}
                  disabled={lastIndex >= paginationProps.totalSize}
                  className="px-4 ml-2"
                >
                  {lang.btn.next}
                </Button>
              </Col>
              <Col className="pl-3 fs--1">
                <CustomTotal {...paginationProps} lastIndex={lastIndex} />
              </Col>
            </Row>
          </Fragment>
        );
      }}
    </PaginationProvider>
  );
};

const prepareColumns = () => [
  {
    dataField: "subject",
    text: lang.invitations.labels.subject,
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    sort: true,
  },
  {
    dataField: "name",
    text: lang.invitations.labels.name,
    classes: "border-0 align-middle",
    headerClasses: "border-0",
  },
  {
    dataField: "service_name",
    text: lang.invitations.labels.service,
    classes: "border-0 align-middle",
    headerClasses: "border-0",
  },
  {
    dataField: "date",
    text: lang.invitations.labels.date,
    classes: "border-0 align-middle",
    headerClasses: "border-0",
  },
  {
    dataField: "time",
    text: lang.invitations.labels.time,
    classes: "border-0 align-middle",
    headerClasses: "border-0",
  },
  {
    dataField: "status",
    text: lang.invitations.labels.status,
    classes: "border-0 align-middle",
    formatter: badgeFormatter,
    headerClasses: "border-0",
  },
  {
    dataField: "action",
    classes: "border-0 align-middle",
    headerClasses: "border-0",
  },
];

const CustomTotal = ({ sizePerPage, totalSize, page, lastIndex }) => (
  <span>
    {(page - 1) * sizePerPage + 1} to {lastIndex > totalSize ? totalSize : lastIndex} of {totalSize} —{" "}
  </span>
);

let table = createRef();
const handleNextPage = ({ page, onPageChange }) => () => {
  onPageChange(page + 1);
};
const handlePrevPage = ({ page, onPageChange }) => () => {
  onPageChange(page - 1);
};

const handleViewAll = ({ onSizePerPageChange }, newSizePerPage) => {
  onSizePerPageChange(newSizePerPage, 1);
};

const badgeFormatter = (status) => {
  let color = "";
  let icon = "";
  let text = "";
  switch (status) {
    case INVITATION_STATUS.PENDING:
      color = "warning";
      text = lang.invitations.status.pending;
      break;
    case INVITATION_STATUS.REJECTED:
      color = "danger";
      text = lang.invitations.status.rejected;
      break;
    case INVITATION_STATUS.ACCEPTED:
      color = "success";
      text = lang.invitations.status.accepted;
      break;
    case INVITATION_STATUS.CANCELED:
      color = "danger";
      text = lang.invitations.status.canceled;
      break;
    case INVITATION_STATUS.EXPIRED:
      color = "secondary";
      text = lang.invitations.status.expired;
      break;
    default:
      text = "-";
  }

  return (
    <Badge color={`soft-${color}`} className="rounded-capsule p-2">
      {text}
      {icon ? <FontAwesomeIcon icon={icon} transform="shrink-2" className="ml-1" /> : null}
    </Badge>
  );
};

export default InvitationsTable;
