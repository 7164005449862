import React, { useState, useEffect, useContext } from "react";
import { Label, Input, Button } from "reactstrap";
import { Select } from "../common/FormSelect";
import { lang } from "../../lang";
import { range, prepareTime, prepareShiftTimeLabel } from "../../utils";
import { request, APIs } from "../../request";
import AppContext from "../../context/Context";
import { toast } from "react-toastify";
import Confirm from "../alert/confirm";

const TIME = range(48).map((index) => {
  let startIndex = 10 + index;
  startIndex > 47 && (startIndex = index - 38);
  const hours = `${Math.floor(startIndex / 2)}`.padStart(2, "0");
  const min = startIndex % 2 ? "30" : "00";
  const time = `${hours}:${min}`;
  const label = prepareShiftTimeLabel(time);
  return { label, value: time };
});

export const Shift = ({ className, deleteShift, updateShift, data = {}, shiftsNumber , shifts = [] }) => {
  const [name, setName] = useState();
  const [days, setDays] = useState([]);
  const [start, setStart] = useState({});
  const [end, setEnd] = useState({});
  const [deleteAlert, setDeleteAlert] = useState(false);

  const { selectedStore } = useContext(AppContext);

  const is_new_shift = !data.id;

  useEffect(() => {
    const retrieved_days = data.days
      ? Object.keys(data.days)
          .filter((d) => {
            return !!data.days[d];
          })
          .map((d) => ({ label: lang.days[d.substring(0, 3)], value: d }))
      : [];
    setDays(retrieved_days);
    setName(data.shift_name || "");

    const start_time = prepareTime(data.start_time);
    const end_time = prepareTime(data.end_time);

    setStart(data.start_time ? { value: start_time, label: prepareShiftTimeLabel(start_time) } : "");
    setEnd(data.end_time ? { value: end_time, label: prepareShiftTimeLabel(end_time) } : "");
  }, [data]);

  const DAYS_OPTIONS = [
    { label: lang.days.sun, value: "sunday" },
    { label: lang.days.mon, value: "monday" },
    { label: lang.days.tue, value: "tuesday" },
    { label: lang.days.wed, value: "wednesday" },
    { label: lang.days.thu, value: "thursday" },
    { label: lang.days.fri, value: "friday" },
    { label: lang.days.sat, value: "saturday" },
  ];

  const submit = async () => {
    // check end time
    if(start.value > end.value ) {
      toast.error(lang.shift.timeError);
      return
    };


// check end time
    if(start.value === end.value ) {
      toast.error(lang.shift.timeError);
      return
    };

    
    // check if shift exists
    const foundStart = shifts.some(el => prepareTime(el.start_time) === start.value);
    const foundEnd = shifts.some(el => prepareTime(el.end_time) === end.value);

    if(foundStart && foundEnd) {
      toast.error(lang.shift.shiftError);
      return
    }


    const new_days = {};
    DAYS_OPTIONS.map((d) => {
      new_days[d.value] = !!days.find((day) => day.value === d.value);
      return d;
    });
    const body = {
      shift_name: name,
      start_time: start.value,
      end_time: end.value,
      days: JSON.stringify(new_days),
      store_id: selectedStore.id,
    };

    try {
      if (!is_new_shift) {
        body.id = data.id;
        const res = await request.PUT(APIs.SHIFT, body);
        updateShift(res.data.data);
        toast.success(lang.shift.updated);
      } else {
        const res = await request.POST(APIs.SHIFT, body);
        updateShift(res.data.data);
        toast.success(lang.shift.created);
      }
    } catch (e) {
      toast.error(lang.defaultError);
      console.log({ e });
    }
  };

  const onRemove = () => {
    is_new_shift ? remove() : setDeleteAlert(true);
  };

  const remove = async () => {
    setDeleteAlert(false);
    if (!is_new_shift) {
      await request.DELETE(APIs.SHIFT, { id: data.id });
      toast.success(lang.shift.deleted);
    }
    deleteShift();
  };

  const validate = () => {
    return name && start && end && days?.length;
  };

  return (
    <article className={`px-3 pb-3 ${className} `}>
      <section className="p-0">
        <div className="col-12 col-md-6 my-4">
          <Label for={`name_${data.id}`}>{lang.shift.name}*</Label>
          <Input onChange={(e) => setName(e.target.value)} id={`name_${data.id}`} value={name} />
        </div>
        <div className="col-12 col-md-6 my-4">
          <Label for="days">{lang.shift.days}*</Label>
          <Select
            closeMenuOnSelect={false}
            value={days}
            onChange={(value) => setDays(value)}
            options={DAYS_OPTIONS}
            isMulti
            id="days"
            placeholder=""
          />
        </div>

        <div className="my-4 pb-4">
          <div className="d-inline-block col-6">
            <Label for="start">{lang.shift.start}*</Label>
            <Select options={TIME} id="start" placeholder="" value={start} onChange={(value) => setStart(value)} />
          </div>
          <div className="d-inline-block col-6">
            <Label for="end">{lang.shift.end}*</Label>
            <Select options={TIME} id="end" placeholder="" value={end} onChange={(value) => setEnd(value)} />
          </div>
        </div>
      </section>
      <section className="p-0 justify-content-between d-flex flex-row-reverse">
        <Button color="primary" className="mr-2" type="submit" disabled={!validate()} onClick={submit}>
          {lang.btn.save}
        </Button>
        <Button color="secondary" className="mr-2" type="button" onClick={onRemove}>
          {lang.btn.remove}
        </Button>
      </section>
      <Confirm
        open={deleteAlert}
        confirm={remove}
        cancel={() => setDeleteAlert(false)}
        title={lang.shift.confirmationDelete}
        body={`${lang.shift.confirmationDeleteMsg} ${shiftsNumber === 1 ? lang.shift.confirmDeleteLastShift : ""}`}
      />
    </article>
  );
};
