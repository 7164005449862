import React from 'react';
import Features from '../../components/landing_page/features';
import Footer from '../../components/landing_page/footer';
import Header from '../../components/landing_page/header';
import Home from '../../components/landing_page/home';
import How from '../../components/landing_page/how';
import Download from '../../components/landing_page/download';
import PrivacyPolicy from '../../components/landing_page/PrivacyPolicy';
import Faq from '../../components/landing_page/Faq';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Route, Switch } from 'react-router-dom';

export const LandingPage = () => {
  return (
    <div className="landing-page">
      <Helmet>
        <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/7732272.js" />
      </Helmet>
      <Header />
      <Switch>
        <Route path={'/'} exact>
          <Home />
          <Features />
          <How />
          <Download />
        </Route>
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
        <Route path="/faq" exact component={Faq} />
      </Switch>
      <Footer />
    </div>
  );
};
