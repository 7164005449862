import React, { Fragment, useState, useEffect, useContext, useCallback } from 'react';
import { Alert, Card, CardBody, Button } from 'reactstrap';
import Loader from '../../components/common/Loader';
import FalconCardHeader from '../../components/common/FalconCardHeader';
import { useHistory } from 'react-router-dom';
import ModeratorsTable from '../../components/moderators/ModeratorsTable';
import { lang } from '../../lang';
import { request, APIs } from '../../request';
import AppContext from '../../context/Context';
import { ROLES } from '../../constants';
import { safeReq } from '../../utils/request';
import Confirm from '../../components/alert/confirm';
import { toast } from 'react-toastify';

const Moderators = () => {
  const history = useHistory();

  const [moderators, setModerators] = useState([]);
  const [selectedModeratorId, setSelectedModeratorId] = useState([]);
  const [selectedModeratorEmail, setSelectedModeratorEmail] = useState([]);

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showResetAlert, setShowResetAlert] = useState(false);

  const [loading, setLoading] = useState(true);
  const { selectedStore } = useContext(AppContext);

  const fetchData = useCallback(async () => {
    const {
      data: { data },
    } = await request.GET(APIs.STORE_STAFF, { store_id: selectedStore.id });
    const moderators = data.filter(m => m.pivot.role === ROLES.MANAGER);
    setModerators(moderators);
    setLoading(false);
  }, [selectedStore.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onDeleteClicked = useCallback(id => {
    setShowDeleteAlert(true);
    setSelectedModeratorId(id);
  }, []);

  const deleteModerator = useCallback(() => {
    safeReq(async () => {
      setShowDeleteAlert(false);
      setLoading(true);
      await request.DELETE(APIs.STAFF, { id: selectedModeratorId });
      setModerators(moderators.filter(el => el.id !== selectedModeratorId));
      toast.success(lang.moderators.deleteManagerSuccess);
      setLoading(false);
    });
  }, [moderators, selectedModeratorId]);

  const onResetClicked = useCallback(email => {
    setShowResetAlert(true);
    setSelectedModeratorEmail(email);
  }, []);

  const resetPassword = useCallback(() => {
    safeReq(async () => {
      setShowResetAlert(false);
      await request.POST(APIs.FORGET_PASSWORD, { email: selectedModeratorEmail });
      toast.success(lang.moderators.resetManagerSuccess);
    });
  }, [selectedModeratorEmail]);

  return (
    <Fragment>
      <Card>
        <FalconCardHeader title={lang.moderators.moderators}>
          <Button color="primary" className="mr-2" onClick={() => history.push('/moderators/add')}>
            {lang.moderators.addModerator.title}
          </Button>
        </FalconCardHeader>
        <CardBody className="fs--1">
          {loading ? (
            <Loader />
          ) : moderators[0] ? (
            <ModeratorsTable
              moderators={moderators}
              onDeleteClicked={onDeleteClicked}
              onResetClicked={onResetClicked}
            />
          ) : (
            <Alert color="info" className="mb-0">
              {lang.moderators.noModeratorsFound}
            </Alert>
          )}
        </CardBody>
      </Card>
      <Confirm
        open={showDeleteAlert}
        confirm={deleteModerator}
        cancel={() => setShowDeleteAlert(false)}
        title={lang.moderators.deleteManagerConfirmationTitle}
        body={lang.moderators.deleteManagerConfirmation}
      />

      <Confirm
        open={showResetAlert}
        confirm={resetPassword}
        cancel={() => setShowResetAlert(false)}
        title={lang.moderators.resetManagerConfirmationTitle}
        body={lang.moderators.resetManagerConfirmation}
      />
    </Fragment>
  );
};

export default Moderators;
