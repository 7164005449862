import React, { useContext, useCallback, useState } from 'react';
import { lang } from '../../lang';
import { Toggler } from '../common/toggler';
import ConfigCard from './_ConfigCard';
import AppContext from '../../context/Context';
import { request, APIs } from '../../request';
import { toast } from 'react-toastify';

export default () => {
  const { selectedStore, setSelectedStore, stores, setStores } = useContext(AppContext);
  const [toggleValue, setToggleValue] = useState(!!selectedStore.configuration?.['walkin-control']);
  let storeId = selectedStore.id;

  const onToggleChange = useCallback(
    async toggleValue => {
      setToggleValue(toggleValue);
      const { data } = await request.PUT(APIs.STORE_CONFIG, {
        store_id: storeId,
        'walkin-control': toggleValue,
      });
      setSelectedStore({ ...selectedStore, configuration: data });
      setStores(
        stores.map(st => {
          const store = { ...st };
          if (store.id === selectedStore.id) {
            store.configuration = data;
          }
          return store;
        })
      );
      toggleValue && toast.success(lang.configuration.walkinControlActivated);
      !toggleValue && toast.success(lang.configuration.walkinControlDeactivated);
    },
    [storeId, stores, setStores, setSelectedStore, selectedStore]
  );

  return (
    <ConfigCard>
      <h5 className="font-weight-bold">{lang.configuration.walkinControl}</h5>
      <small className="m-0 px-3" style={{ flex: 1 }}>
        {lang.configuration.walkinControlExplaination}
      </small>
      <Toggler checked={toggleValue} onChange={onToggleChange} />
    </ConfigCard>
  );
};
