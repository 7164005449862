/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { lang, isAR } from '../../lang';
import iphone from '../../assets/img/yasser/home/iphone-yasser@2x.png';
import app_store_en from '../../assets/img/badges/app_store_en.svg';
import google_play_en from '../../assets/img/badges/google_play_en.svg';
import app_store_ar from '../../assets/img/badges/app_store_ar.svg';
import google_play_ar from '../../assets/img/badges/google_play_ar.svg';

export default () => (
  <section
    id="home"
    className={`home pb-0 mb-0 d-flex align-items-end text-white bg-primary cover-offset-${isAR ? 'ar' : 'en'}`}
  >
    <div className="d-flex home-container mt-6">
      <div className="mt-0 w-md-50 w-xl-50 mx-xl-5 px-xl-5 px-md-3 mx-md-3 px-sm-1 mx-sm-1 my-auto">
        <h1 className="text-white mb-md-4 mb-4 mt-1">{lang.yasser.landing.home.title}</h1>
        <h3 className="text-white mb-6">{lang.yasser.landing.home.body}</h3>
        <div className="d-flex badges">
          <a
            // href="https://play.google.com/store/apps/details?id=sa.gov.my.appointments"
            // target="_blank"
            rel="noopener noreferrer"
          >
            <img src={isAR ? google_play_ar : google_play_en} alt="play" />
          </a>
          <a
            // href="https://apps.apple.com/sa/app/gov-sa-national-platform/id1516850299"
            // target="_blank"
            rel="noopener noreferrer"
          >
            <img src={isAR ? app_store_ar : app_store_en} alt="pause" className="ml-4" />
          </a>
        </div>
      </div>
      <img src={iphone} className="yasser-iphone" alt="iphone" />
    </div>
  </section>
);
