import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { isAR, lang } from '../../lang';
import { groupBy } from '../../utils';
import { Card } from '../common/Card';
import { VerticalBarGraph } from '../dashboard/VerticalBarGraph';

const MostBookedServiceGraph = ({ appts: raw_appts, services: raw_services, className, loading }) => {
  const [values, setValues] = useState([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const nameIndex = useMemo(() => (isAR ? 'name_ar' : 'name_en'), [isAR]);

  const prepareData = useCallback(() => {
    // making sure to not run the calculations without the needed data
    if (raw_appts && raw_services.length) {
      // group appts by serviceID
      const index = 'service.id';
      const groupedApptsByServiceID = groupBy(raw_appts, index);

      // calculate number of booking for each service and get store name
      const targeted = raw_services
        ?.sort((a, b) => (groupedApptsByServiceID[b.id]?.length || 0) - groupedApptsByServiceID[a.id]?.length || 0)
        .slice(0, 6);

      const targeted_values = targeted.map(ser => {
        const numOfBookings = groupedApptsByServiceID[ser.id]?.length || 0;
        const groupedAppts = groupedApptsByServiceID[ser.id];
        const storeName = groupedAppts && groupedAppts[0]?.store[nameIndex];
        const value = Math.round((numOfBookings / raw_appts.length) * 100) || 0;
        const res = {
          value,
          label: ser[nameIndex],
          tooltip: `<span class="font-weight-bold">${
            lang.dashboardItems.branch
          }:</span> ${storeName}. <br /> <span class="font-weight-bold">${lang.dashboardItems.service}:</span> ${
            ser[nameIndex]
          }.`,
        };
        return res;
      });

      setValues(targeted_values);
    }
  }, [raw_services, raw_appts, nameIndex]);

  useEffect(() => {
    prepareData();
  }, [prepareData]);

  return (
    <Card loading={loading} className={`${className || ''} response-time h-100 w-100`}>
      <div className="dashboard-card-title">{lang.dashboardItems.mostBookedServices}</div>
      <div className="d-flex h-100 w-100">
        <VerticalBarGraph data={values} barUnit="%" />
      </div>
    </Card>
  );
};

MostBookedServiceGraph.propTypes = {
  appts: PropTypes.array.isRequired,
  services: PropTypes.array.isRequired,
};

export default MostBookedServiceGraph;
